/*
 * File: PublicRouteWrapper.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 12:24 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 12:24 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';


/**
 * Route Wrapper
 */

export const PublicRouteWrapper = ({
	children, onClick
// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
}) => <div onClick={onClick}>{children}</div>;


/**
 * Configuration
 */

PublicRouteWrapper.propTypes = {
	children: PropTypes.element,
	onClick: PropTypes.func
};
PublicRouteWrapper.defaultProps = {
	children: null,
	onClick: null
};
