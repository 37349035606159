/*
 * File: TextInput.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 4:02 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 10, 2024 at 2:46 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
 	flex-direction: column;
 	position: relative;

 	& > label {
	 	cursor: pointer;
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.secondaryText)} !important;
 	}
`;

const SIZES = {
	fit: css`
		width: 100%;
		font-size: 0.9rem;
	`,
	small: css`
		font-size: 0.75rem;
		min-height: 2.25rem;
 `,
	medium: css`
		font-size: 1rem;
		min-height: 2.8125rem;
 `,
};

export const TextInput = styled.input`
 	appearance: none;
 	padding: 0 0.75rem;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	color: ${({ theme }) => theme.primaryText};
	line-height: normal;
	width: 100%;
	outline: none;
	background-color: ${({ theme }) => theme.inputBackground};

 	${({ size }) => SIZES[size]}

	&[type='password'] {
		letter-spacing: 0.25rem;
	}

	&:focus {
		border-color: ${({ theme }) => theme.inputFocusBorder};
	}

	&:read-only {
		background-color: ${({ theme }) => theme.inputSecondayBackground};
		border-color: ${({ theme }) => theme.inputBorder};
	}

 	${({ withIcon }) => withIcon && css`
		padding-left: 2.25rem;
	`}

 	${({ error }) => error && css`
		color: ${({ theme }) => theme.statusDangerBase};
		border-color: ${({ theme }) => theme.statusDangerBase};

		:focus {
			border-color: ${({ theme }) => theme.statusDangerBase};
		}
	`}
`;

export const FloatingWrapper = styled.div`
 	position: absolute;
 	bottom: -20px;
 	color: ${({ theme }) => theme.statusDangerBase} !important;
	margin: 0 !important;
 	max-width: 100%;

 	p {
		text-align: left;
	 	white-space: nowrap;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
		color: ${({ theme }) => theme.statusDangerBase} !important;
		margin: 0 !important;
 	}
`;

export const IconWrapper = styled.div`
 	position: absolute;
 	display: flex;
 	height: 100%;
 	justify-content: center;
 	align-items: center;
 	padding: 0 0.75rem;
 	pointer-events: none;

 	svg {
	 	color: ${({ theme }) => theme.inputPlaceholder};
 	}
`;
