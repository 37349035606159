/*
 * File: routes.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 11:48 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 30, 2024 at 11:48 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { matchPath } from 'react-router-dom';


/**
 * Utilities
 */

export const filterProps = (path, props) => {

	// If data is undefined, return props
	if (!props || !props.data) return props;

	// Search for route path
	const routes = Object.keys(props.data).filter((key) => key.startsWith('/'));
	const matchRoute = routes.find((route) => matchPath(path, route));

	// Check for exact match
	let exactMatch = true;
	if (typeof window !== 'undefined') {
		const fullPath = (props?.locale?.url?.fullPath !== '' ? props?.locale?.url?.fullPath?.replace(/\/$/, '') : props?.locale?.url?.fullPath) || '';
		let basePath = fullPath.startsWith(props?.locale?.localePath) ? fullPath.replace(props?.locale?.localePath, '') : fullPath;
		if (basePath === '') basePath = '/';
		exactMatch = fullPath === window.location.pathname || `${fullPath}/` === window.location.pathname || basePath === window.location.pathname;
	}

	// Return data
	return (props.data ? {
		...props,
		data: {
			...props?.data.global,
			...matchRoute && exactMatch ? props?.data[matchRoute] : undefined
		}
	} : props);
};

export const prepareComponent = (routeObj) => (Array.isArray(routeObj) ? routeObj.map((route) => ({
	...route,
	component: route.component(route.path)
})) : {
	...routeObj,
	component: routeObj.component(routeObj.path)
});

export const allowRoles = (role, roles) => (roles || []).includes(role);
