/*
 * File: Conversation.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 9:14 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 9:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
`;

export const StepContainer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-grow: 1;
`;

export const Step = styled.div`
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 0px;
	pointer-events: none;
	width: 100%;
	flex-grow: 1;
	display: flex;
	height: 100%;

	&.show {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.3s;
		position: relative;
		pointer-events: initial;
	}
`;
