/*
 * File: Badge.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 11:14 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './Badge.styles';


/**
 * Constants
 */

const FONT_SIZES_VARIATIONS = {
	small: '3',
	medium: '2',
};


/**
 * Component
 */

export const Badge = ({
	children, size, variant, variation, className, type, icon, ...rest
}) => (
	<S.Badge
		size={size}
		variant={variant}
		variation={variation}
		type={type}
		iconPlacement={icon?.placement || 'left'}
		className={className ? `${className} animate` : 'animate'}
		{...rest}
	>
		{icon && <FontAwesomeIcon className="badge-icon" icon={icon.source} />}
		<Typography tag="span" variation={FONT_SIZES_VARIATIONS[size]} weight="light">
			{children}
		</Typography>
	</S.Badge>
);


/**
 * Configuration
 */

Badge.displayName = 'Badge';
Badge.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	type: PropTypes.oneOf(['primary', 'neutral', 'dark', 'danger', 'warning', 'success']),
	variant: PropTypes.oneOf(['solid', 'outlined']),
	variation: PropTypes.oneOf(['square', 'pill']),
	className: PropTypes.string,
	icon: PropTypes.shape({
		source: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		placement: PropTypes.oneOf(['left', 'right']),
	}),
};
Badge.defaultProps = {
	children: null,
	size: 'medium',
	type: 'primary',
	variant: 'solid',
	variation: 'pill',
	className: null,
	icon: null
};
