/*
 * File: Error.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on June 13, 2024 at 10:21 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 10, 2024 at 10:44 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
`;
