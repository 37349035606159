/*
 * File: PublicScreen.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on June 13, 2024 at 10:21 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 27, 2024 at 8:15 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import socketIOClient from 'socket.io-client';
import { v4 } from 'uuid';
import { useWindowResize } from 'beautiful-react-hooks';
import Lottie from 'react-lottie-player';
import { flatten } from 'lottie-colorify';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';
import { getAssetUrl } from '../../../../../utilities/partner';

// Services
import { getConversation } from '../../../../services/conversation';

// Slices
import { setCurrentConversation, clearConversation } from '../../../../store/slices/conversation/conversation.slice';

// Components
import {
	Badge,
	CircleDecoration,
	Emoji,
	Messenger,
	Meta,
	Typography,
	UserAIProfile,
} from '../../../../components';

// Constants
import {
	CONVERSATION_ROLES, SOCKET_ACTIONS, SOCKET_COMMAND_KEY, UI_MODE_OPTIONS
} from '../../../../../Constants';

// Animation
import pulseAnimationData from '../../../../assets/animations/pulse.json';
import spinnerAnimationData from '../../../../assets/animations/spinner.json';

// Styles
import * as S from './PublicScreen.styles';
import { DarkTheme, LightTheme } from '../../../../styles/colors';


/**
 * Component
 */

const PublicScreen = ({ meta, locale, partner }) => {

	// Create reference for step components
	const [qrStepHeight, setQRStepHeight] = useState(0);
	const [loadingStepHeight, setLoadingStepHeight] = useState(0);
	const [lighthouseStepHeight, setLighthouseStepHeight] = useState(0);
	const qrStepRef = useRef();
	const loadingStepRef = useRef();
	const lighthouseStepRef = useRef();

	// Create state handlers
	const [qrCodeURI, setQRCodeURI] = useState(null);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [currentStep, setCurrentStep] = useState(1);
	const [conversationId, setConversationId] = useState(v4());

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current partner from hook
	const clientPartner = useSelector((state) => state.partner.value);
	const statePartner = partner || clientPartner;

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current theme
	const theme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme(partner) : LightTheme(partner);

	// Use hooks
	const dispatch = useDispatch();

	// Create refs
	const socketRef = useRef();

	// Generate QR code
	const generateQRCode = async () => {
		const code = await QRCode.toDataURL(`${process.env.APP_URL}/conversation/${conversationId}`, {
			scale: 10,
			color: {
				dark: theme.brandPrimaryBase,
				light: 'FFFFFF'
			}
		});
		setQRCodeURI(code);
	};

	// Handle fetch conversation
	const fetchCurrentConversation = async () => {

		// Update current step
		setCurrentStep(2);

		// Fetch current conversation
		const { data: { conversation } } = await getConversation({ conversationId });

		// Set current conversation
		dispatch(setCurrentConversation(conversation));

		// Update current step
		setTimeout(() => {
			setCurrentStep(3);
		}, 3000);
	};

	// Handle actions on session id change
	useEffect(() => {

		// Generate QR code URI
		generateQRCode();

		// Disconnect and refresh socket
		if (socketRef.current) socketRef?.current?.disconnect();

		// Ensure conversation id exists
		if (conversationId) {

			// Create a WebSocket connection
			socketRef.current = socketIOClient(process.env.SOCKET_URL, {
				transports: ['websocket'],
				query: { conversationId },
			});

			// Listen for incoming commands
			socketRef.current.on(SOCKET_COMMAND_KEY, (message) => {

				// Get parameters from payload
				const {
					action
				} = message.payload;

				// Handle action
				if (action === SOCKET_ACTIONS.START_CONVERSATION) {

					// Update current step
					fetchCurrentConversation();

				}
			});
		}

		// Destroys the socket reference when the connection is closed
		return () => { socketRef?.current?.disconnect(); };

	}, [conversationId]);

	// Handle actions on component load
	useEffect(() => {

		// Clear conversation
		dispatch(clearConversation());

	}, []);

	// Handle actions on step change
	useEffect(() => {
		if (process.env.USE_PUBLIC_SCREEN_TIMEOUT === 'true') {
			if (currentStep === 3) {
				setTimeout(() => {

					// Reset public screen
					setConversationId(v4());
					setCurrentStep(1);

					// Clear conversation
					dispatch(clearConversation());

				}, 2 * 60 * 1000);
			}
		}
	}, [currentStep]);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setQRStepHeight(qrStepRef?.current?.clientHeight);
			setLoadingStepHeight(loadingStepRef?.current?.clientHeight);
			setLighthouseStepHeight(lighthouseStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setQRStepHeight(qrStepRef?.current?.clientHeight);
		setLoadingStepHeight(loadingStepRef?.current?.clientHeight);
		setLighthouseStepHeight(lighthouseStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return qrStepHeight;
			case 2:
				return loadingStepHeight;
			case 3:
				return lighthouseStepHeight;
			default:
				return 0;
		}
	};

	// Handle render component
	const renderComponent = () => (
		<S.StepContainer className="animate" stepHeight={getStepHeight()}>

			{/* QR Step */}
			<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
				<S.StepInner ref={qrStepRef}>
					<S.StepContentContainer>

						{/* Instructions */}
						<S.InstructionsContainer>
							<S.Logo src={getAssetUrl(uiMode.mode === UI_MODE_OPTIONS.DARK ? partner?.logo_light : partner?.logo_dark, '/public/assets/logos/')} />
							<Typography tag="h1" weight="bold">
								Introducing
								{' '}
								lighthouse.
								<br />
								Conversational
								{' '}
								<span>support & triage</span>
								{' '}
								for survivors.
							</Typography>
							<Typography tag="h4" weight="bold">
								<span style={{ marginRight: '8px' }}>Scan for a live demo</span>
								<Emoji symbol="💬" size={1.4} label="Celebration" />
							</Typography>
						</S.InstructionsContainer>

						{/* Content */}
						<S.ContentContainer>
							<S.QRCodeContainer>
								<S.QRCode src={qrCodeURI} />
								<S.AnimationContainer $opacity={0.7}>
									<Lottie
										loop
										play
										animationData={flatten(theme.brandAccent, pulseAnimationData)}
										style={{
											width: '100%',
											height: '100%'
										}}
									/>
								</S.AnimationContainer>
							</S.QRCodeContainer>
						</S.ContentContainer>

						{/* Decoration */}
						<CircleDecoration />

					</S.StepContentContainer>
				</S.StepInner>
			</S.Step>

			{/* Loading Step */}
			<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
				<S.StepInner ref={loadingStepRef}>
					<S.StepPadding left right>
						<S.StepContentContainer>

							{/* Content */}
							<S.ContentContainer>
								<Typography tag="h3" weight="bold">
									<span style={{ marginRight: '8px' }}>Welcome to lighthouse</span>
									<Emoji symbol="👋" size={1.5} label="Wave" />
								</Typography>
								<Typography tag="h1" weight="bold">
									We&apos;re setting up your personalized experience...
								</Typography>

								{/* Animation */}
								<S.BuildProgressContainer>
									<S.AnimationContainer $opacity={1}>
										<Lottie
											loop
											play
											animationData={flatten(theme.brandPrimaryBase, spinnerAnimationData)}
											style={{
												width: '100%',
												height: '100%'
											}}
										/>
									</S.AnimationContainer>
								</S.BuildProgressContainer>
							</S.ContentContainer>

							{/* Decoration */}
							<CircleDecoration />

						</S.StepContentContainer>
					</S.StepPadding>
				</S.StepInner>
			</S.Step>

			{/* Lighthouse Step */}
			<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
				<S.StepInner ref={lighthouseStepRef}>

					{/* Lighthouse Container */}
					<S.LighthouseContainer>

						{/* Content Container */}
						{/* <S.ExplanationContainer>
							<S.Logo src={getAssetUrl(uiMode.mode === UI_MODE_OPTIONS.DARK ? partner?.logo_light : partner?.logo_dark, '/public/assets/logos/')} />
							<Typography tag="h2" weight="bold">
								<span style={{ marginRight: '8px' }}>Continue the conversation on your device</span>
								<Emoji symbol="➡️" size={1.4} label="Arrow right" className="arrowRight" />
								<Emoji symbol="⬇️" size={1.4} label="Arrow down" className="arrowDown" />
							</Typography>
							<Typography tag="h4" weight="medium">
								lighthouse provides timely resources for survivors to help them find healing after trauma.
							</Typography>
							<Typography tag="h4" weight="medium">
								The chat experience can be embedded on a website, mobile + other integrations.
							</Typography>

						</S.ExplanationContainer> */}

						{/* Messenger Container */}
						<S.MessengerContainer>

							{/* Conversation View Badge */}
							<Badge>
								<Typography weight="medium" tag="p" variation="2">
									Conversation with Survivor
								</Typography>
							</Badge>

							{/* Messenger */}
							<Messenger
								conversationId={conversationId}
								role={CONVERSATION_ROLES.SYSTEM}
								isVisible={currentStep === 3}
								className="widget"
							/>

						</S.MessengerContainer>

						{/* User Profile Container */}
						<S.UserProfileContainer>

							{/* Conversation View Badge */}
							<Badge>
								<Typography weight="medium" tag="p" variation="2">
									Real-time Survivor Triage
								</Typography>
							</Badge>

							{/* User Profile */}
							<UserAIProfile
								className="widget"
								conversationId={conversationId}
								partner={statePartner}
							/>

						</S.UserProfileContainer>

					</S.LighthouseContainer>
				</S.StepInner>
			</S.Step>
		</S.StepContainer>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} partner={statePartner} />

			{/* Component Content */}
			<S.Wrapper>{renderComponent()}</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

PublicScreen.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	partner: PropTypes.shape(),
};
PublicScreen.defaultProps = {
	meta: {},
	locale: {},
	partner: {}
};


/**
 * Exports
 */

export default PublicScreen;
