/*
 * File: constants.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 11:50 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 10, 2024 at 10:52 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Breakpoints
 */

// Max Width
export const xSmallBreakpoint = 22; // 20em
export const smallBreakpoint = 25; // 25em
export const mediumBreakpoint = 45; // 45em
export const largeBreakpoint = 60; // 60em
export const xLargeBreakpoint = 75; // 75em
export const xxLargeBreakpoint = 85; // 90em
export const xxxLargeBreakpoint = 100; // 100em

// Min Width
export const mobileBreakpoint = 62; // 62em


/**
 * Dimensions
 */

export const AppNavTopbarHeight = 68; // 68px
