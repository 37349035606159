/*
 * File: partner.core.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 4:40 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 8, 2024 at 9:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Partner Config
 */

module.exports = {
	id: 'core',
	domain: 'lighthouse.ourwave.org',
	full_domain: 'lighthouse.ourwave.org',
	favicon: 'favicon.ico',
	logo: 'our-wave-logo-horizontal-dark-blue-no-tagline.png',
	logo_light: 'our-wave-logo-horizontal-white-no-tagline.png',
	logo_dark: 'our-wave-logo-horizontal-dark-blue-no-tagline.png',
	logo_mark: 'our-wave-mark-dark-solo.png',
	logo_mark_light: 'our-wave-mark-white-solo.png',
	logo_mark_dark: 'our-wave-mark-dark-solo.png',
	mobile_icon_192: 'icon192.png',
	mobile_icon_512: 'icon512.png',
	website: 'https://www.ourwave.org',
	name: 'Our Wave',
	description: 'Our Wave is a safe harbor for survivors of sexual harm and their allies.',
	entities: [
		'https://www.facebook.com/ourwavestories',
		'https://twitter.com/ourwavestories',
		'https://www.linkedin.com/company/ourwave',
		'https://www.instagram.com/ourwavestories/',
		'https://medium.com/@ourwave',
		'https://www.tiktok.com/@ourwavestories?lang=en'
	],
	twitter: {
		handle: 'ourwavestories'
	},
	colors: {

		// Brand
		brand_primary_base: '#44566c',
		brand_accent: '#9ebed8',

		// Components
		primary_background: '#fffef8',
		primary_text: '#44566c'
	},
	fonts: {
		primary_url: 'https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap',
		primary_family: "'Outfit', Verdana, Arial, Helvetica, sans-serif"
	},
};
