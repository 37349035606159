/*
 * File: i18n.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 11:17 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 30, 2024 at 11:17 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Constants
import {
	AVAILABLE_I18N_NAMESPACES, AVAILABLE_LOCALES, DEFAULT_LOCALE, LOCALE_COOKIE
} from '../Constants';


/**
 * Options
 */

export const buildOptions = (isServer = false, isHot = false) => ({
	debug: false,
	fallbackLng: DEFAULT_LOCALE,
	supportedLngs: AVAILABLE_LOCALES,
	preload: AVAILABLE_LOCALES,
	load: 'languageOnly', // We only provide en, de -> no region specific locals like en-US, de-DE
	saveMissing: true,
	ns: Object.values(AVAILABLE_I18N_NAMESPACES),
	defaultNS: AVAILABLE_I18N_NAMESPACES.TRANSLATIONS,
	interpolation: {
		escapeValue: false,
		formatSeparator: ',',
		format: (value, format) => {
			if (format === 'uppercase') return value.toUpperCase();
			return value;
		},
	},
	react: {
		useSuspense: !isServer && isHot
	},
	...!isServer && isHot ? {
		detection: {
			order: ['cookie'],
			lookupCookie: LOCALE_COOKIE
		}
	} : undefined
});


/**
 * Utilities
 */

export const buildInstance = (options, isServer = false) => {

	// Initialize React i18n if needed
	if (!isServer) {

		// Set React options
		i18n.use(Backend).use(initReactI18next).use(LanguageDetector);
	}

	// Initialize if not already initialized
	if (!i18n.isInitialized) {
		i18n.init(options);
	}
};
