/*
 * File: Topbar.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 11, 2024 at 1:38 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Colors
import { ColorValuesCreator } from '../../../styles/colors';

// Constants
import { AppNavTopbarHeight, mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Topbar = styled.div`
	height: ${AppNavTopbarHeight}px;
	min-height: ${AppNavTopbarHeight}px;
	display: flex;
	background-color: ${({ theme }) => theme.primaryBackground};
	z-index: 1000;
	padding: 0.78rem 0;
	pointer-events: all;
	top: 0px !important;
	width: 100%;

	${({ isFixed }) => isFixed && css`
		position: fixed !important;
	`}
	${({ isScrolled, isFixed }) => isScrolled && isFixed && css`
		background-color: ${({ theme }) => theme.primaryBackground};
	`}
	${({ isScrolled, isFixed, partner }) => (isScrolled && isFixed ? css`
		box-shadow: ${ColorValuesCreator(partner).navigationShadow};
		border-bottom: 1px solid ${({ theme }) => theme.navigationBorder};
	` : css`
		box-shadow: none;
		border-bottom: 1px solid transparent;
	`)};
	* :not(svg,path) {
		height: 100%;
	}
	button {
		pointer-events: auto;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-left: 20px;

		* {
			height: auto !important;
		}
		@media only screen and (min-width: ${mobileBreakpoint}em) {
			margin-left: 30px;
		}
	}
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;

	${({ fullWidth }) => fullWidth && css`
		justify-content: center;
		flex: 1;
	`}
	.logoImage {
		img {
			padding: 0.2rem 0px 0.2rem 0;
		}
	}
`;
