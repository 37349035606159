/*
 * File: CircleDecoration.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 2:23 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './CircleDecoration.styles';


/**
 * Component
 */

export const CircleDecoration = ({
	className
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.CircleDecoration className={className} />
	);
};


/**
 * Configuration
 */

CircleDecoration.displayName = 'CircleDecoration';
CircleDecoration.propTypes = {
	className: PropTypes.string
};
CircleDecoration.defaultProps = {
	className: null,
};
