/*
 * File: Constants.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 4:37 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 5, 2024 at 7:04 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Timezone
 */

export const DEFAULT_TIMEZONE = 'America/New_York';


/**
 * UI Mode Options
 */

export const UI_MODE_OPTIONS = {
	LIGHT: 'light',
	DARK: 'dark'
};


/**
 * Cookies
 */

export const LOCALE_COOKIE = 'pl_locale';
export const UI_MODE_COOKIE = 'ui_mode';


/**
 * Cookie Domain
 */

export const COOKIE_DOMAIN = (partner) => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return `qa.${partner.domain}`;
		case 'staging':
			return `staging.${partner.domain}`;
		case 'production':
			return partner.full_domain;
		default:
			return null;
	}
};


/**
 * Headers
 */

export const CLIENT_PLATFORM_HEADER = 'x-ow-client-platform';
export const CLIENT_VERSION_HEADER = 'x-ow-client-version';


/**
 * Expirations
 */

export const LOCALE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const UI_MODE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year


/**
 * Available Locales
 */

export const AVAILABLE_LOCALES = ['en', 'es'];
export const DEFAULT_LOCALE = 'en';
export const AVAILABLE_I18N_NAMESPACES = {
	TRANSLATIONS: 'translations'
};


/**
 * Core Domain Whitelists
 */

export const CORE_DOMAIN_WHITELIST = () => [
	'https://*.ourwave.org',
	'https://our-wave-ai-*.herokuapp.com/',
	process.env.ENV === 'development' && [
		'http://localhost:*'
	]
].filter(Boolean);


/**
 * Alert Types
 */

export const ALERT_STYLES = {
	ERROR: 'error',
	INFO: 'info'
};


/**
 * Error Messages
 */

export const GENERAL_ERROR_MESSAGE = 'Something went wrong, try again later!';


/**
 * Socket Actions
 */

export const SOCKET_ACTIONS = {
	START_CONVERSATION: 'start_conversation',
	UPDATE_SUGGESTED_PROMPTS: 'update_suggested_prompts',
	NEW_MESSAGE: 'new_message',
	UPDATE_MESSAGE: 'update_message',
	PROFILE_UPDATE_DESCRIPTORS: 'profile_update_descriptors',
	PROFILE_NEW_INTENT: 'profile_new_intent',
	PROFILE_UPDATE_RISK_ASSESSMENT: 'profile_update_risk_assessment'
};


/**
 * Socket Keys
 */

export const SOCKET_COMMAND_KEY = 'command';
export const SOCKET_MESSAGE_KEY = 'message';
export const SOCKET_PROFILE_UPDATE_KEY = 'updateProfile';


/**
 * Conversation Roles
 */

export const CONVERSATION_ROLES = {
	USER: 'user',
	SYSTEM: 'system'
};
