/*
 * File: ErrorComponent.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 12:17 AM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 11:50 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Meta } from '../Meta';
import { Typography } from '../Typography';
import { Button } from '../Button';

// Styles
import * as S from './ErrorComponent.styles';


/**
 * Component
 */

export const ErrorComponent = ({
	locale, title, description, message, buttons, partner
}) => (
	<>
		{/* Meta */}
		<Meta
			meta={{
				title: `Page Not Found ~ ${partner.name}`,
				description: 'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.',
				bodyClasses: 'hide-captcha',
				showSocial: false,
			}}
			locale={locale}
			partner={partner}
		/>

		{/* Component Content */}
		<S.Wrapper>
			<S.ContentContainer>
				<S.MessageContainer>
					<Typography tag="h1" weight="bold">
						{title || 'Whoops!'}
					</Typography>
					<S.DescriptionContainer>
						<Typography tag="h4" weight="light">
							{description || 'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.'}
						</Typography>
					</S.DescriptionContainer>
					{message !== '' && (
						<Typography tag="h4" weight="bold">
							{message}
						</Typography>
					)}
				</S.MessageContainer>
				<S.ActionContainer fullWidth={!buttons}>
					{
						buttons ? buttons.map((button) => (
							<S.ButtonLink to={button.link} key={button.text}>
								<Button size={button.size} variant={button.variant || 'solid'} partner={partner}>{button.text}</Button>
							</S.ButtonLink>
						)) : (
							<S.ButtonLink to="/">
								<Button size="large" partner={partner}>Back to Home</Button>
							</S.ButtonLink>
						)
					}
				</S.ActionContainer>
			</S.ContentContainer>
		</S.Wrapper>
	</>
);


/**
 * Configuration
 */

ErrorComponent.displayName = 'ErrorComponent';
ErrorComponent.propTypes = {
	locale: PropTypes.shape(),
	partner: PropTypes.shape(),
	title: PropTypes.string,
	description: PropTypes.node,
	message: PropTypes.string,
	buttons: PropTypes.arrayOf(PropTypes.shape())
};
ErrorComponent.defaultProps = {
	locale: {},
	partner: {},
	title: null,
	description: null,
	message: null,
	buttons: null
};
