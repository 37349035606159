/*
 * File: position.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 11:48 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 30, 2024 at 11:48 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const scrollPageTo = (x, y, options = {}) => {
	if (typeof window !== 'undefined') {
		window.scroll({ top: y, left: x, ...options });
	}
};
