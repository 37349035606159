/*
 * File: colors.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on August 2, 2024 at 1:12 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 2, 2024 at 1:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const polarityColor = (sentiment, theme) => {
	switch (sentiment) {
		case 'positive':
			return theme.statusSuccessBase;
		case 'neutral':
			return theme.statusWarningBase;
		case 'negative':
			return theme.statusDangerBase;
		default:
			return theme.statusWarningBase;
	}
};

export const polarityBgColor = (sentiment, theme) => {
	switch (sentiment) {
		case 'positive':
			return theme.statusSuccessLighter;
		case 'neutral':
			return theme.statusWarningLighter;
		case 'negative':
			return theme.statusDangerLighter;
		default:
			return theme.statusWarningLighter;
	}
};
