/*
 * File: ErrorComponent.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 12:20 AM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 11, 2024 at 1:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink } from '../LocaleLink';

// Constants
import { mediumBreakpoint, smallBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;

export const ContentContainer = styled.div`
	text-align: center;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		margin-bottom: 20px;
	}
	h4 {
		margin: 5px 0 35px;
	}
`;

export const MessageContainer = styled.div`
	width: 100%;

	@media only screen and (min-width: ${smallBreakpoint}em) {
		margin: 0px auto 0px;
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 800px;
	}

	* {
		text-align: center !important;
	}
	a,
	a:visited,
	a:link {
		color: inherit;
	}
`;

export const DescriptionContainer = styled.div`
	width: 100%;
	margin: 0px auto 50px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 700px;
	}
`;

export const ActionContainer = styled.div`
	text-align: center;
	margin: -30px auto 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	button {
		width: 100%;
		font-size: 1.1rem;
		margin-top: 15px;
		padding-left: 50px;
		padding-right: 50px;
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-wrap: nowrap;
	}
`;

export const ButtonLink = styled(LocaleLink)`
	text-decoration: none;
`;
