/*
 * File: index.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 12:05 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 9:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import localeReducer from './slices/locale/locale.slice';
import uiReducer from './slices/ui/ui.slice';
import conversationReducer from './slices/conversation/conversation.slice';
import profileReducer from './slices/profile/profile.slice';
import partnerReducer from './slices/partner/partner.slice';


/**
 * Store
 */

export default configureStore({
	reducer: {
		locale: localeReducer,
		ui: uiReducer,
		conversation: conversationReducer,
		profile: profileReducer,
		partner: partnerReducer
	},
});
