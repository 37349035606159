/*
 * File: CircleDecoration.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 11:55 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { xxLargeBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const CircleDecoration = styled.div`
	background-color: ${({ theme }) => theme.brandAccent};
	border-radius: 50%;
	position: fixed;
	width: 120vw;
	height: 120vw;
	bottom: -100vw;
	left: -10vw;

	@media only screen and (min-width: ${xxLargeBreakpoint}em) {
		width: 1400px;
		height: 1400px;
		bottom: -1200px;
		left: calc(50vw - 700px);
	}
`;
