/*
 * File: conversation.slice.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 12:08 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 1, 2024 at 3:42 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		messages: [],
		suggestedPrompts: []
	},
};


/**
 * Slices
 */

export const conversationSlice = createSlice({
	name: 'conversation',
	initialState,
	reducers: {
		setCurrentConversation: (state, action) => {

			// Set state
			state.status = 'success';
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		pushMessageToConversation: (state, action) => {

			// Set state
			state.status = 'success';
			state.value = {
				isSet: true,
				messages: [
					...state.value.messages,
					action.payload
				]
			};
		},
		updateMessage: (state, action) => {

			// Get messages
			const { messages } = state.value;

			// Update message
			const messageIndex = messages.findIndex(({ id }) => action.payload.id === id);
			if (messageIndex > -1) {
				messages[messageIndex] = {
					...messages[messageIndex],
					...action.payload
				};
			}

			// Set state
			state.status = 'success';
			state.value = {
				isSet: true,
				messages
			};

		},
		clearConversation: (state) => {

			// Clear state
			state.status = 'idle';
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const {
	setCurrentConversation, pushMessageToConversation, updateMessage, clearConversation
} = conversationSlice.actions;

export default conversationSlice.reducer;
