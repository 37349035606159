/*
 * File: index.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on June 13, 2024 at 10:16 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 2:16 PM
 * Modified By: Brendan Michaelsen
 */

// Polyfill IE
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Modules
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import { useSSR } from 'react-i18next';

// Analytics
import TagManager from 'react-gtm-module';

// Styles
import { config } from '@fortawesome/fontawesome-svg-core';
import { loadIcons } from '../styles/fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';

// Utilities
import { buildInstance, buildOptions } from '../../utilities/i18n';

// Stores
import store from '../store';

// App
import CoreApp from './core/App';
import DemoApp from './demo/App';

// Components
import { Spinner } from '../components';

// Constants
import { DEFAULT_LOCALE } from '../../Constants';

// Configuration
config.autoAddCss = false;


/**
 * Constants
 */

const MODES = {
	CORE: 'core',
	DEMO: 'demo'
};


/**
 * State
 */

const isDevelopment = process.env.ENV === 'development';
const isProduction = process.env.ENV === 'production';
const isHot = import.meta.webpackHot != null;


/**
 * Mode
 */

const currentMode = document.getElementById('data-app-mode').innerHTML ? document.getElementById('data-app-mode').innerHTML.trim() : MODES.DEMO;


/**
 * Accept Hot Reload
 */

if (isHot && isDevelopment) {
	import.meta.webpackHot.accept();
}


/**
 * Initialize Sentry
 */

if (!isDevelopment) {
	Sentry.init({
		dsn: process.env.SENTRY_REACT_DSN,
		release: `our-wave-ai@${process.env.PACKAGE_VERSION}`,
		environment: process.env.ENV,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}


/**
 * Initialize Google Tag Manager
 */

if (isProduction) {
	TagManager.initialize({ gtmId: process.env.GTM_ID });
}


/**
 * Load Icons
 */

loadIcons();


/**
 * Initialize i18n
 */

buildInstance({
	...buildOptions(false, isHot),
	loadPath: `${process.env.APP_URL}/locales/{{lng}}/{{ns}}.json`,
	addPath: `${process.env.APP_URL}/locales/{{lng}}/{{ns}}.missing.json`,
	backend: {
		loadPath: `${process.env.APP_URL}/locales/{{lng}}/{{ns}}.json`,
		addPath: `${process.env.APP_URL}/locales/{{lng}}/{{ns}}.missing.json`
	}
}, false);


/**
 * Render App
 */

// Determine render function
const isMarkupPresent = document.getElementById('root').hasChildNodes();
const renderFunction = isMarkupPresent ? ReactDOM.hydrate : ReactDOM.render;

// Create inner application component
const BaseApp = () => (
	<>
		<BrowserRouter>
			{currentMode === MODES.CORE ? <CoreApp isHot={isHot} /> : <DemoApp isHot={isHot} />}
		</BrowserRouter>
		<Toaster />
	</>
);

// Create application component
const ParentApp = () => {

	// Get data if SSR mode
	if (!isHot) {

		// Get component state on client
		let state = { locale: { initialI18nStore: {}, initialLanguage: DEFAULT_LOCALE } };
		try { state = JSON.parse(decodeURIComponent(window.document.getElementById('data-state').innerHTML)); } catch (e) { }

		// Use SSR from hook
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSSR(state.locale.initialI18nStore, state.locale.initialLanguage);
	}

	// Render components
	return (
		<React.StrictMode>
			<Provider store={store}>
				<HelmetProvider>
					{isHot ? (
						<Suspense fallback={<Spinner showMeta />}>
							<BaseApp />
						</Suspense>
					) : (
						<BaseApp />
					)}
				</HelmetProvider>
			</Provider>
		</React.StrictMode>
	);
};

// Prepare content
renderFunction(
	<ParentApp />,
	document.getElementById('root')
);
