/*
 * File: colors.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 11:50 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 2, 2024 at 1:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Color Values
 */

export const ColorValuesCreator = (partner) => ({

	// Brand
	brandPrimaryBase: partner?.colors?.brand_primary_base,
	brandAccent: partner?.colors?.brand_accent,

	// Specific colors
	primaryBackground: partner?.colors?.primary_background,
	primaryText: partner?.colors?.primary_text,

	// Primary
	white: '#FFFFFF',
	dark: '#242424',

	// Neutral
	neutralButtonBase: '#747888',
	neutralButtonLighter: '#EEF0F6',
	neutralBackground: '#EFF1F3',
	neutralBackgroundDarker: '#39393B',
	neutralHighlight: '#E2E2E2',
	neutralHighlightDarker: '#505054',
	neutralForm: '#DBDFEC',
	neutralText: '#7F8496',
	neutralBorder: 'rgba(74,74,74,.05)',

	// Success
	statusSuccessBase: '#25953E',
	statusSuccessLighter: '#EAFFEE',
	statusSuccessDarker: '#04290D',

	// Warning
	statusWarningBase: '#ECB500',
	statusWarningLighter: '#FFFDE7',
	statusWarningDarker: '#302B05',

	// Danger
	statusDangerBase: '#E13737',
	statusDangerLighter: '#FFEBEB',
	statusDangerDarker: '#360404',

	// Info
	statusInfoBase: '#1A4ED4',
	statusInfoLighter: '#E7EDFF',
	statusInfoDarker: '#0B1D4D',

	// Shadows
	navigationShadow: '0px 1px 1px rgb(0 0 0 / 15%)',
	elementShadow: '0px -3px 20px rgba(0, 0, 0, 0.06)',
	elementMediumShadow: '0 15px 35px #31315d1a,0 5px 15px #00000014',
	elementDarkShadow: '0px 4px 16px rgba(0 0 0 / 22%)',

	// Brands
	whatsAppBrand: '#25d366',
	facebookBrand: '#0075fa',
	twitterBrand: '#1da1f2',
	linkedinBrand: '#0a66c2',
	redditBrand: '#ff4500',
	pinterestBrand: '#e60019'
});


/**
 * Themes
 */

export const DarkTheme = (partner) => {

	// Create color values
	const ColorValues = ColorValuesCreator(partner);

	// Return theme
	return {

		// Brand
		brandPrimaryBase: ColorValues.brandPrimaryBase,
		brandAccent: ColorValues.brandAccent,

		// Success
		statusSuccessBase: ColorValues.statusSuccessBase,
		statusSuccessLighter: ColorValues.statusSuccessDarker,

		// Warning
		statusWarningBase: ColorValues.statusWarningBase,
		statusWarningLighter: ColorValues.statusWarningDarker,

		// Danger
		statusDangerBase: ColorValues.statusDangerBase,
		statusDangerLighter: ColorValues.statusDangerDarker,

		// Info
		statusInfoBase: ColorValues.statusInfoBase,
		statusInfoLighter: ColorValues.statusInfoDarker,

		// Background
		plainBackground: ColorValues.dark,
		primaryBackground: ColorValues.dark,
		secondaryBackground: ColorValues.neutralBackgroundDarker,
		cardBackground: ColorValues.dark,

		// Text
		primaryText: ColorValues.white,
		secondaryText: ColorValues.neutralText,

		// Button
		primaryButton: ColorValues.brandPrimaryBase,
		secondaryButton: ColorValues.neutralButtonBase,
		darkButton: ColorValues.dark,

		// Decoration
		primaryDecoration: ColorValues.white,
		secondaryDecoration: ColorValues.neutralBorder,
		layoutBorder: ColorValues.neutralText,
		navigationBorder: ColorValues.neutralText,
		inactiveElement: ColorValues.neutralText,
		placeholderPrimary: ColorValues.neutralBackgroundDarker,
		placeholderHighlight: ColorValues.neutralHighlightDarker,

		// Gradient
		primaryGradientStart: 'rgba(0,0,0,0)',
		primaryGradientEnd: 'rgba(0,0,0,1)',

		// Input
		inputBackground: ColorValues.dark,
		inputSecondayBackground: ColorValues.neutralBackground,
		inputBorder: ColorValues.neutralText,
		inputFocusBorder: ColorValues.neutralButtonBase,
		inputPlaceholder: ColorValues.neutralText,

		// Font
		fontFamily: partner?.fonts?.primary_family
	};
};

export const LightTheme = (partner) => {

	// Create color values
	const ColorValues = ColorValuesCreator(partner);

	// Return theme
	return {

		// Brand
		brandPrimaryBase: ColorValues.brandPrimaryBase,
		brandAccent: ColorValues.brandAccent,

		// Success
		statusSuccessBase: ColorValues.statusSuccessBase,
		statusSuccessLighter: ColorValues.statusSuccessLighter,

		// Warning
		statusWarningBase: ColorValues.statusWarningBase,
		statusWarningLighter: ColorValues.statusWarningLighter,

		// Danger
		statusDangerBase: ColorValues.statusDangerBase,
		statusDangerLighter: ColorValues.statusDangerLighter,

		// Info
		statusInfoBase: ColorValues.statusInfoBase,
		statusInfoLighter: ColorValues.statusInfoLighter,

		// Background
		plainBackground: ColorValues.white,
		primaryBackground: ColorValues.primaryBackground || ColorValues.white,
		secondaryBackground: ColorValues.neutralBackground,
		cardBackground: ColorValues.white,

		// Text
		primaryText: ColorValues.primaryText || ColorValues.dark,
		secondaryText: ColorValues.neutralText,

		// Button
		primaryButton: ColorValues.brandPrimaryBase,
		secondaryButton: ColorValues.neutralButtonBase,
		darkButton: ColorValues.dark,

		// Decoration
		primaryDecoration: ColorValues.dark,
		secondaryDecoration: ColorValues.neutralBorder,
		layoutBorder: ColorValues.neutralForm,
		navigationBorder: 'transparent',
		inactiveElement: ColorValues.neutralText,
		placeholderPrimary: ColorValues.neutralBackground,
		placeholderHighlight: ColorValues.neutralHighlight,

		// Gradient
		primaryGradientStart: 'rgba(255,255,255,0)',
		primaryGradientEnd: 'rgba(255,255,255,1)',

		// Input
		inputBackground: ColorValues.white,
		inputSecondayBackground: ColorValues.neutralBackground,
		inputBorder: ColorValues.neutralForm,
		inputFocusBorder: ColorValues.neutralButtonBase,
		inputPlaceholder: ColorValues.neutralForm,

		// Font
		fontFamily: partner?.fonts?.primary_family
	};
};
