/*
 * File: partner.slice.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 9:00 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 9:01 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
	},
};


/**
 * Slices
 */

export const partnerSlice = createSlice({
	name: 'partner',
	initialState,
	reducers: {
		updatePartner: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearPartner: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updatePartner, clearPartner } = partnerSlice.actions;

export default partnerSlice.reducer;
