/*
 * File: MessengerStep.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 12:30 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
`;
