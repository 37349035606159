/*
 * File: Topbar.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 10, 2024 at 2:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Components
import { Padding } from '../../Padding';
import { Button } from '../../Button';
import { Typography } from '../../Typography';

// Styles
import * as S from './Topbar.styles';
import { Logo } from '../../Logo';


/**
 * Component
 */

export const Topbar = ({
	isFixed, partner
}) => {

	// Set state
	const [isScrolled, setIsScrolled] = useState(false);

	// Create references for components
	const isMounted = useRef(true);

	// Use hooks
	const navigate = useNavigate();

	// Handle actions on component load
	useEffect(() => {

		// Initialize temporary state
		isMounted.current = true;

		// Attach scroll listener
		if (isMounted.current) {
			document.addEventListener('scroll', () => {
				if (isMounted.current) {

					// Get current scroll status
					const st = document.scrollingElement.scrollTop;

					// Update scrolled status
					setIsScrolled(st >= 20);
				}
			}, true);
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<S.Topbar
			isFixed={isFixed}
			isScrolled={isScrolled}
			partner={partner}
			className="animate"
		>
			<Padding left right>
				<S.TopbarWrapper>
					<Logo partner={partner} className="logoImage" />
				</S.TopbarWrapper>
				<S.TopbarWrapper fullWidth />
				<S.TopbarWrapper>
					<Button onClick={() => { navigate('/welcome'); }}>
						<Typography variation="button-medium" weight="medium">
							Try a demo
						</Typography>
					</Button>
				</S.TopbarWrapper>
			</Padding>
		</S.Topbar>
	);
};


/**
 * Configuration
 */

Topbar.displayName = 'Topbar';
Topbar.propTypes = {
	isFixed: PropTypes.bool,
	partner: PropTypes.shape(),
};
Topbar.defaultProps = {
	isFixed: true,
	partner: {}
};
