/*
 * File: Logo.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 11, 2024 at 1:37 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Utilities
import { getAssetUrl } from '../../../utilities/partner';

// Components
import { LocaleLink } from '../LocaleLink';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './Logo.styles';


/**
 * Component
 */

export const Logo = ({
	className, partner, useMark, link
}) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render logo variant
	const renderLogoVariant = (isMark) => {
		if (partner.id === 'core') {
			if (new Date().getMonth() === 5) {
				if (isMark) {
					return 'our-wave-mark-pride-solo.png';
				}
				return uiMode.mode === UI_MODE_OPTIONS.DARK ? 'our-wave-logo-pride-white-no-tagline.png' : 'our-wave-logo-pride-dark-no-tagline.png';
			}
		}
		if (isMark) {
			return uiMode.mode === UI_MODE_OPTIONS.DARK ? partner.logo_mark_light : partner.logo_mark_dark;
		}
		return uiMode.mode === UI_MODE_OPTIONS.DARK ? partner.logo_light : partner.logo_dark;
	};

	// Render component
	return (
		<S.Wrapper className={className}>
			<LocaleLink to={link}>
				<S.LogoImage
					className="smallScreen"
					alt={`${partner.name} Logo`}
					title={`${partner.name} Logo`}
					src={getAssetUrl(renderLogoVariant(true), '/public/assets/logos/')}
				/>
				<S.LogoImage
					className="largeScreen"
					alt={`${partner.name} Logo`}
					title={`${partner.name} Logo`}
					src={getAssetUrl(renderLogoVariant(!!useMark), '/public/assets/logos/')}
				/>
			</LocaleLink>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Logo.displayName = 'Logo';
Logo.propTypes = {
	className: PropTypes.string,
	partner: PropTypes.shape(),
	link: PropTypes.string,
	useMark: PropTypes.bool
};
Logo.defaultProps = {
	className: null,
	partner: {},
	link: '/',
	useMark: false
};
