/*
 * File: PublicScreen.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on June 13, 2024 at 10:21 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 4:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../components';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Constants
import {
	mediumBreakpoint,
	xLargeBreakpoint,
	xxLargeBreakpoint
} from '../../../../styles/constants';

// Color Values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
`;

export const StepContainer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	display: flex;
	flex-grow: 1;
`;

export const StepPadding = styled(Padding)`
	height: 100%;
`;

export const Step = styled.div`
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 0px;
	pointer-events: none;
	width: 100%;
	height: 100%;
	flex-grow: 1;
	display: flex;
	z-index: 99;
	height: 100%;

	&.show {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
		position: relative;
		pointer-events: initial;
	}
`;

export const StepInner = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const StepContentContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const QRCodeContainer = styled.div`
	position: relative;
	width: 40vw;
	height: 40vw;
	max-width: 40vh;
	max-height: 40vh;
	margin: 60px 0px 0px;
`;

export const BuildProgressContainer = styled.div`
	position: relative;
	width: 20vw;
	height: 20vw;
	max-width: 20vh;
	max-height: 20vh;
	margin: 0px 0px 0px;
	z-index: 3;
`;

export const CompletionIconContainer = styled.div`
	position: relative;
	width: 30vw;
	height: 30vw;
	max-width: 30vh;
	max-height: 30vh;
	margin: 50px 0px 0px;
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 50%;
	box-shadow: ${ColorValues.elementDarkShadow};
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: ${({ theme }) => theme.brandSecondaryBase};
		font-size: 4.0rem;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			font-size: 9.0rem;
		}
	}
`;

export const QRCode = styled.img`
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 20px;
	box-shadow: ${ColorValues.elementDarkShadow};
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 2;
`;

export const AnimationContainer = styled.div`
	position: absolute;
	width: 180%;
	height: 180%;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -40%;
	left: -40%;
	opacity: ${({ $opacity }) => $opacity};

	&.largeAnimation {
		width: 300%;
		height: 300%;
		top: -100%;
		left: -100%;
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 90vw;
	position: relative;

	@media only screen and (min-width: ${xxLargeBreakpoint}em) {
		width: auto;
		max-width: 60vw;
	}
	h1 {
		font-size: 2.0rem;
		position: relative;
		z-index: 2;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			font-size: 3.6rem;
		}
		span {
			text-decoration: underline;
			text-decoration-color: ${({ theme }) => theme.brandSecondaryBase};
		}
	}
	h3 {
		margin: 0px 0px 20px;
	}
`;

export const InstructionsContainer = styled.div`
	position: relative;
	z-index: 3;
	max-width: 90vw;
	text-align: center;

	h1 {
		font-size: 2.0rem;
		position: relative;
		z-index: 2;
		margin: 0px 0px 10px;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			font-size: 4.0rem;
		}
		span {
			text-decoration: underline;
			text-decoration-color: ${({ theme }) => theme.brandSecondaryBase};
		}
	}
	h4 {
		padding: 10px 20px;
		display: inline-block;
		margin: 30px 0px 0px;
	}
`;

export const LighthouseContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding: 3rem 4rem;

	.widget {
		border-radius: 20px;
		overflow: hidden;
		border: 1px solid ${({ theme }) => theme.layoutBorder};
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		flex-direction: row;
		align-items: unset;
	}
`;

export const ExplanationContainer = styled.div`
	width: 100%;
	max-width: 420px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	* {
		text-align: center;
	}
	.arrowRight {
		display: none;
	}
	.arrowDown {
		display: inline;
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		width: 40vw;
		align-items: start;
		justify-content: end;

		* {
			text-align: left;
		}
		.arrowRight {
			display: inline;
		}
		.arrowDown {
			display: none;
		}
	}
	h4 {
		margin-top: 20px;
	}
`;

export const MessengerContainer = styled.div`
	width: 100%;
	max-width: 460px;
	min-height: 700px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;

	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		width: 40vw;
	}
`;

export const UserProfileContainer = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	width: 100%;

	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		width: auto;
	}
`;

export const Logo = styled.img`
	width: 200px;
	margin-bottom: 30px;
`;
