/*
 * File: Routes.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 12:36 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 3:12 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { Navigate } from 'react-router-dom';

// Utilities
import { createLocaleVariants } from '../../../utilities/locale';
import { filterProps, prepareComponent } from '../../../utilities/routes';

// Slice
import { initialState as initialLocale } from '../../../store/slices/locale/locale.slice';

// Navigation
import { PublicRouteWrapper } from '../../../navigation/PublicRouteWrapper';

// Pages
import {
	Conversation,
	PublicScreen,
	Error
} from '../pages';


/**
 * Routes
 */

export const Routes = (props) => {

	// Get current locale from props
	const localeObj = props?.locale?.localePath != null ? props?.locale : initialLocale.value;

	// Get current meta from props
	const metaObj = props?.meta;

	// Get current partner from props
	const partnerObj = props?.partner;

	// Render routes
	return [].concat(...[

		/**
		 * Core
		 */

		prepareComponent(createLocaleVariants({
			path: '/conversation/:conversationId',
			exact: true,
			shouldIndex: false,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj} partner={partnerObj}>
						<Conversation
							{...filterProps(path, props)}
							meta={{
								title: 'My Conversation with Lighthouse',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/welcome',
			exact: true,
			shouldIndex: false,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj} partner={partnerObj}>
						<PublicScreen
							{...filterProps(path, props)}
							meta={{
								title: 'Welcome to Lighthouse ~ {{partner.name}}',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Redirects
		 */

		prepareComponent(createLocaleVariants({
			path: '/',
			exact: true,
			component() {
				return (
					<Navigate to="/welcome" replace />
				);
			}
		})),


		/**
		 * Error
		 */

		prepareComponent({
			path: '*',
			shouldIndex: false,
			isError: true,
			code: 404,
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj} partner={partnerObj}>
						<Error
							code={404}
							{...filterProps(path, props)}
							locale={localeObj}
							meta={{
								title: 'Page Not Found ~ {{partner.name}}',
								description: 'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.',
								bodyClasses: 'hide-captcha',
								showSocial: false,
								shouldIndex: false,
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})
	].filter(Boolean));
};
