/*
 * File: locale.slice.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 12:08 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 12:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';

// Constants
import { DEFAULT_LOCALE } from '../../../../Constants';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		localeId: DEFAULT_LOCALE,
		localeBaseId: DEFAULT_LOCALE.split('-')[0],
		localePath: ''
	},
};


/**
 * Slices
 */

export const localeSlice = createSlice({
	name: 'locale',
	initialState,
	reducers: {
		updateLocale: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearLocale: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateLocale, clearLocale } = localeSlice.actions;

export default localeSlice.reducer;
