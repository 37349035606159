/*
 * File: MessengerStep.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 8:26 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, forwardRef
} from 'react';
import PropTypes from 'prop-types';

// Components
import {
	Messenger,
} from '../../../../../components';

// Constants
import { CONVERSATION_ROLES } from '../../../../../../Constants';

// Styles
import * as S from './MessengerStep.styles';


/**
 * Component
 */

export const MessengerStep = forwardRef(({
	className, conversationId, isVisible
}, ref) => {

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Messenger */}
			<Messenger
				conversationId={conversationId}
				role={CONVERSATION_ROLES.USER}
				isVisible={isVisible}
			/>

		</S.StepContainer>
	);
});


/**
 * Configuration
 */

MessengerStep.displayName = 'MessengerStep';
MessengerStep.propTypes = {
	className: PropTypes.string,
	conversationId: PropTypes.string,
	isVisible: PropTypes.bool
};
MessengerStep.defaultProps = {
	className: null,
	conversationId: null,
	isVisible: false
};
