/*
 * File: profile.slice.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 12:09 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 12:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		profile: {
			behaviorDescriptors: []
		}
	},
};


/**
 * Slices
 */

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		updateProfile: (state, action) => {

			// Set state
			state.value = {
				isSet: true,
				profile: action.payload
			};
		},
		clearProfile: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateProfile, clearProfile } = profileSlice.actions;

export default profileSlice.reducer;
