/*
 * File: Error.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 9:13 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 9:16 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, ErrorComponent
} from '../../../../components';

// Styles
import * as S from './Error.styles';


/**
 * Component
 */

const Error = ({ meta, locale, partner }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current partner from hook
	const clientPartner = useSelector((state) => state.partner.value);
	const statePartner = partner || clientPartner;

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} partner={statePartner} />

			{/* Component Content */}
			<S.Wrapper>
				<ErrorComponent partner={statePartner} />
			</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

Error.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	partner: PropTypes.shape(),
};
Error.defaultProps = {
	meta: {},
	locale: {},
	partner: {}
};


/**
 * Exports
 */

export default Error;
