/*
 * File: dateTime.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on August 1, 2024 at 4:21 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 1, 2024 at 4:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Constants
import { DEFAULT_TIMEZONE } from '../Constants';

// Plugins
dayjs.extend(timezonePlugin);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);


/**
 * Utilities
 */

export const formatDateString = (date, format, timezone = DEFAULT_TIMEZONE, adjustTimezone = true) => (adjustTimezone ? dayjs(date || new Date()).tz(timezone).format(format) : dayjs(date || new Date()).format(format));

export const parseDateString = (value, format, timezone) => (timezone ? dayjs.tz(value, format, timezone) : dayjs(value, format));

export const dateObj = (date, timezone = DEFAULT_TIMEZONE) => dayjs(date || new Date()).tz(timezone);

export const timezoneDateObj = (date, timezone = DEFAULT_TIMEZONE) => dayjs.tz(date, timezone);

export const rawDateObj = (date) => dayjs(date || new Date());

export const validateDateFormat = (date, format) => dayjs(date, format, true).isValid();

export const formatMinuteLength = (minutes) => `${Math.ceil(minutes)} min read`;
