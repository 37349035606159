/*
 * File: UserAIProfile.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 5, 2024 at 12:41 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Utilities
import { polarityBgColor, polarityColor } from '../../utilities/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.secondaryBackground};
	padding: 20px;
	gap: 20px;

	.widgetBadge {
		padding: 0.1rem 0.5275rem;
	}
`;

export const TopSection = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	gap: 20px;
`;

export const BottomSection = styled.div`
	width: 100%;
	flex-shrink: 0;
`;

export const FlowContainer = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	.widgetCard {
		height: 0px;
		overflow: scroll;
	}
`;

export const SentimentContainer = styled.div`
	min-width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.widgetCard {
		height: 0px;
		overflow: scroll;
	}
`;

export const DescriptorsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const WidgetCard = styled.div`
	margin: 10px 0px 0px;
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 20px;
	flex-grow: 1;
	width: 100%;
	padding: 15px;

	&.flex {
		display: flex;
		align-items: center;
	}
`;

export const TriageContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0px;
`;

export const SentimentTimeline = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0px;
`;

export const AnimationContainer = styled.div`
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${({ $opacity }) => $opacity};
	margin-top: -15px;
`;

export const EmptyContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	span {
		color: ${({ theme }) => theme.secondaryText};
		margin-top: -10px;
		font-size: 0.7075rem;
	}
`;

export const TimelineRow = styled.div`
	display: flex;
	gap: 7px;
	position: relative;
	align-items: center;
	padding: 8px 0px;

	&.largePadding {
		padding: 12px 0px;
	}
	.secondaryText {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const SentimentBadge = styled.div`
	border: 1px solid ${({ $sentiment, theme }) => polarityColor($sentiment, theme)};
	border-radius: 18px;
	padding: 2px 12px;
	color: ${({ $sentiment, theme }) => polarityColor($sentiment, theme)};
	background-color: ${({ $sentiment, theme }) => polarityBgColor($sentiment, theme)};
`;

export const TimelineDot = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	flex-shrink: 0;
`;

export const TimelineDivider = styled.div`
	width: 2px;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	flex-shrink: 0;
	position: absolute;
	height: 100%;
	left: 3px;

	${({ $start }) => $start && css`
		top: 50%;
		height: 50%;
	`}
	${({ $end }) => $end && css`
		bottom: 50%;
		height: 50%;
	`}
`;

export const Descriptors = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;
	min-height: 60px;
	max-height: 110px;
	overflow: scroll;
`;

export const Descriptor = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3px;

	.widgetBadge {
		background-color: ${({ theme, $polarity }) => polarityBgColor($polarity, theme)};
		color: ${({ theme, $polarity }) => polarityColor($polarity, theme)};
		border-color: ${({ theme, $polarity }) => polarityColor($polarity, theme)};
	}
`;

export const ProgressBar = styled.div`
	width: ${({ $percentage }) => $percentage || 100}%;
	height: 3px;
	background-color: ${({ theme, $polarity }) => polarityColor($polarity, theme)};
	border-radius: 4px;
`;

export const SuccessContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	flex-shrink: 0;

	${({ $isComplete, theme }) => $isComplete && css`
		background-color: ${theme.statusSuccessBase};
	`}
	svg {
		color: ${({ theme }) => theme.secondaryBackground};
	}
`;

export const TimelineAnimation = styled.div`
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${({ $opacity }) => $opacity};
	width: 100px;
	height: 100px;
	flex-shrink: 0;
	position: absolute;
	left: -18px;
`;

export const TriageContent = styled.div`
	padding: 12px;
	background-color: ${({ theme }) => theme.plainBackground};
	border-radius: 8px;
	margin-top: 5px;
`;
