/*
 * File: ExplanationStep.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 2:26 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button, Padding } from '../../../../../components';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StepPadding = styled(Padding)`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	z-index: 2;

	p {
		margin-top: 15px;
	}
	h3 {
		margin-bottom: 15px;
	}
`;

export const ActionButton = styled(Button)`
	margin: 40px auto 0px;
`;
