/*
 * File: Button.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 11, 2024 at 1:07 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../styles/colors';


/**
 * Constants
 */

const SIZES = {
	small: css`
		padding: 0.3125rem 0.625rem;
	`,
	medium: css`
		padding: 0.5625rem 1.5625rem;
	`,
	large: css`
		padding: 0.8625rem 1.8625rem;
	`,
};

const VARIATIONS = {
	default: {
		solid: css`
			background-color: ${({ theme }) => theme.primaryButton};
			border-color: ${({ theme }) => theme.primaryButton};
			color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.primaryButton};
			color: ${({ theme }) => theme.primaryButton};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.primaryButton};
		`,
	},
	white: {
		solid: css`
			background-color: ${({ partner }) => ColorValuesCreator(partner).white};
			border-color: ${({ partner }) => ColorValuesCreator(partner).white};
			color: ${({ partner }) => ColorValuesCreator(partner).dark};
			box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ partner }) => ColorValuesCreator(partner).white};
			color: ${({ partner }) => ColorValuesCreator(partner).white};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ partner }) => ColorValuesCreator(partner).white};
		`,
	},
	dark: {
		solid: css`
			background-color: ${({ theme }) => theme.darkButton};
			border-color: ${({ theme }) => theme.darkButton};
			color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.darkButton};
			color: ${({ theme }) => theme.darkButton};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.darkButton};
		`,
	},
	secondary: {
		solid: css`
			background-color: ${({ theme }) => theme.secondaryButton};
			border-color: ${({ theme }) => theme.secondaryButton};
			color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.secondaryButton};
			color: ${({ theme }) => theme.secondaryButton};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.secondaryButton};
		`,
	},
	warning: {
		solid: css`
			background-color: ${({ theme }) => theme.statusDangerBase};
			border-color: ${({ theme }) => theme.statusDangerBase};
			color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.statusDangerBase};
			color: ${({ theme }) => theme.statusDangerBase};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.statusDangerBase};
		`,
	},
};

const SHORTCUT_VARIATIONS = {
	default: {
		solid: css`
			background-color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: ${({ theme }) => theme.primaryButton};
		`,
		text: css`
			background-color: ${({ theme }) => theme.primaryButton};
		`,
	},
	white: {
		solid: css`
			background-color: ${({ partner }) => ColorValuesCreator(partner).dark};
		`,
		outline: css`
			background-color: ${({ partner }) => ColorValuesCreator(partner).white};
		`,
		text: css`
			background-color: ${({ partner }) => ColorValuesCreator(partner).white};
		`,
	},
	dark: {
		solid: css`
			background-color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: ${({ theme }) => theme.darkButton};
		`,
		text: css`
			background-color: ${({ theme }) => theme.darkButton};
		`,
	},
	secondary: {
		solid: css`
			background-color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: ${({ theme }) => theme.secondaryButton};
		`,
		text: css`
			background-color: ${({ theme }) => theme.secondaryButton};
		`,
	},
	warning: {
		solid: css`
			background-color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: ${({ theme }) => theme.statusDangerBase};
		`,
		text: css`
			background-color: ${({ theme }) => theme.statusDangerBase};
		`,
	},
};


/**
 * Styles
 */

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	border-radius: 4px;
	letter-spacing: 0.05rem;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
	font-weight: 500;
	font-size: 1.1rem;
	position: relative;

	* {
		letter-spacing: 0.05rem;
	}
	& > * + * {
		margin-left: 0.625rem;
	}
	.noMargin {
		margin-left: 0px;
	}
	${({ size, variant, variation }) => css`
		${SIZES[size]}
		${VARIATIONS[variation][variant]}

		.shortcut {
			${SHORTCUT_VARIATIONS[variation][variant]}
		}
	`}
	&:hover {
		opacity: 0.8;
	}
	&:disabled {
		opacity: 0.55;
		cursor: default;
	}
`;

export const SpinnerContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`;

export const Span = styled.span`
	margin: 0px !important;
	height: auto !important;
`;

export const ShortcutContainer = styled.div`
	position: relative;
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 0.8rem
	}
`;

export const ShortcutBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	top: 0px;
	left: 0px;
	border-radius: 5px;
`;
