/*
 * File: partner.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 5:19 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 11:19 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const getAssetUrl = (asset, localPath) => (asset?.startsWith('http') ? asset : `${process.env.CDN_URL}${localPath}${asset}`);
