/*
 * File: Conversation.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 9:14 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 4:30 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Services
import { postBeginConversation } from '../../../../services/conversation';

// Slices
import { setCurrentConversation } from '../../../../store/slices/conversation/conversation.slice';

// Components
import {
	Meta,
	Spinner,
} from '../../../../components';

// Step Components
import { ExplanationStep } from './ExplanationStep';
import { MessengerStep } from './MessengerStep';


// Styles
import * as S from './Conversation.styles';


/**
 * Component
 */

const Conversation = ({ meta, locale, partner }) => {

	// Get component parameters
	const { conversationId } = useParams();

	// Create reference for step components
	const [explanationStepHeight, setExplanationStepHeight] = useState(0);
	const [messengerStepHeight, setMessengerStepHeight] = useState(0);
	const explanationStepRef = useRef();
	const messengerStepRef = useRef();

	// Create state handlers
	const [currentStep, setCurrentStep] = useState(1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [componentStatus, setComponentStatus] = useState('idle');

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current partner from hook
	const clientPartner = useSelector((state) => state.partner.value);
	const statePartner = partner || clientPartner;

	// Use hooks
	const dispatch = useDispatch();

	// Create reference for components
	const isMounted = useRef(true);

	// Initialize component data function
	const notifyBeginConversation = async () => {

		// Set success state
		setComponentStatus('success');
		try {

			// Set loading state
			setComponentStatus('loading');

			// Begin build session
			const { data: { conversation, isNew } } = await postBeginConversation({ conversationId });

			// Set success state
			setComponentStatus('success');

			// Set current step
			setCurrentStep(isNew ? 1 : 2);

			// Set current conversation
			dispatch(setCurrentConversation(conversation));

		} catch (error) {

			// Set error state
			setComponentStatus('error');
		}
	};

	// Handle actions on component load
	useEffect(() => {

		// Notify begin build session
		notifyBeginConversation();

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setExplanationStepHeight(explanationStepRef?.current?.clientHeight);
			setMessengerStepHeight(messengerStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setExplanationStepHeight(explanationStepRef?.current?.clientHeight);
		setMessengerStepHeight(messengerStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return explanationStepHeight;
			case 2:
				return messengerStepHeight;
			default:
				return 0;
		}
	};

	// Handle render content
	const renderComponent = () => {
		if (componentStatus === 'idle' || componentStatus === 'loading') {
			return <Spinner isFull partner={partner} />;
		}
		return (
			<S.StepContainer className="animate" stepHeight={getStepHeight()}>
				<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
					<ExplanationStep
						ref={explanationStepRef}
						updateStep={setCurrentStep}
						updateHeight={setShouldUpdateHeight}
					/>
				</S.Step>
				<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
					<MessengerStep
						ref={messengerStepRef}
						updateStep={setCurrentStep}
						updateHeight={setShouldUpdateHeight}
						conversationId={conversationId}
						isVisible={currentStep === 2}
					/>
				</S.Step>
			</S.StepContainer>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} partner={statePartner} />

			{/* Component Content */}
			<S.Wrapper>{renderComponent()}</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

Conversation.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	partner: PropTypes.shape(),
};
Conversation.defaultProps = {
	meta: {},
	locale: {},
	partner: {}
};


/**
 * Exports
 */

export default Conversation;
