/*
 * File: Spinner.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:32 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 11, 2024 at 1:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import Lottie from 'react-lottie-player';
import { replaceColor } from 'lottie-colorify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Meta } from '../Meta';
import { CircleDecoration } from '../CircleDecoration';

// Animation
import animationData from '../../assets/animations/spinner.json';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Partners
import CORE from '../../../partners/partner.core';

// Styles
import * as S from './Spinner.styles';
import { DarkTheme, LightTheme } from '../../styles/colors';


/**
 * Component
 */

export const Spinner = ({
	className, partner, showMeta, meta, isFull
}) => {

	// Get partner if necessary
	const statePartner = (!partner || !partner.id) ? CORE : partner;

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme(statePartner) : LightTheme(statePartner);

	// Return component
	return (
		<S.Wrapper className={className}>

			{/* Meta */}
			{showMeta && <Meta meta={meta} partner={statePartner} />}

			{/* Component Content */}
			<S.Content>

				{/* Animation */}
				<Lottie loop play animationData={replaceColor('#ffffff', currentTheme.brandPrimaryBase, animationData)} style={{ width: 260, height: 260, zIndex: '3' }} />

			</S.Content>

			{/* Decoration */}
			{isFull && <CircleDecoration />}

		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Spinner.displayName = 'Spinner';
Spinner.propTypes = {
	className: PropTypes.string,
	partner: PropTypes.shape(),
	showMeta: PropTypes.bool,
	meta: PropTypes.shape(),
	isFull: PropTypes.bool
};
Spinner.defaultProps = {
	className: null,
	partner: {},
	showMeta: false,
	meta: { shouldIndex: false },
	isFull: true
};
