/*
 * File: AppNavigation.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:29 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 10, 2024 at 3:04 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	createContext, forwardRef, useEffect
} from 'react';
import PropTypes from 'prop-types';

// Components
import { Topbar } from './Topbar';

// Styles
import * as S from './AppNavigation.styles';


/**
 * Context
 */

export const AppNavigationContext = createContext();


/**
 * Component
 */

export const AppNavigation = forwardRef(({
	children, contentPadding, isFixed, partner, containerClassName
}, ref) => {

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Navigation ref={ref} className={containerClassName}>

			{/* Top Bar */}
			<Topbar
				partner={partner}
				isFixed={isFixed}
			/>

			{/* Content */}
			<S.Wrapper>
				<S.Content id="scroller" $isFixed={isFixed}>

					{/* Content */}
					{contentPadding ? (
						<S.ContentPadding left right dynamic>
							{children}
						</S.ContentPadding>
					)
						: children}

				</S.Content>
			</S.Wrapper>
		</S.Navigation>
	);
});


/**
 * Configuration
 */

AppNavigation.displayName = 'AppNavigation';
AppNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	partner: PropTypes.shape(),
	contentPadding: PropTypes.bool,
	containerClassName: PropTypes.string,
	isFixed: PropTypes.bool,
};
AppNavigation.defaultProps = {
	children: null,
	partner: {},
	contentPadding: true,
	containerClassName: null,
	isFixed: true,
};
