/*
 * File: UserAIProfile.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 5, 2024 at 12:42 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import socketIOClient from 'socket.io-client';
import Lottie from 'react-lottie-player';
import { flatten } from 'lottie-colorify';
import { useSelector } from 'react-redux';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateString } from '../../../utilities/dateTime';

// Components
import { Badge } from '../Badge';
import { Typography } from '../Typography';

// Constants
import { SOCKET_ACTIONS, SOCKET_PROFILE_UPDATE_KEY, UI_MODE_OPTIONS } from '../../../Constants';

// Animation
import pulseAnimationData from '../../assets/animations/pulse.json';
import spinnerAnimationData from '../../assets/animations/spinner.json';

// Styles
import * as S from './UserAIProfile.styles';
import { DarkTheme, LightTheme } from '../../styles/colors';


/**
 * Component
 */

export const UserAIProfile = ({
	className, conversationId, partner
}) => {

	// Create state handlers
	const [profileDescriptors, setProfileDescriptors] = useState([]);
	const [riskAssessmentStarted, setRiskAssessmentStarted] = useState(false);
	const [riskAssessmentComplete, setRiskAssessmentComplete] = useState(false);
	const [riskAssessmentResult, setRiskAssessmentResult] = useState(null);
	const [triageIntents, setTriageIntents] = useState([]);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current conversation from hook
	const conversation = useSelector((state) => state.conversation.value);
	const conversationStatus = useSelector((state) => state.conversation.status);

	// Get current theme
	const theme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme(partner) : LightTheme(partner);

	// Create refs
	const socketRef = useRef();
	const triageIntentsRef = useRef([]);

	// Handle actions on session id change
	useEffect(() => {

		// Disconnect and refresh socket
		if (socketRef.current) socketRef?.current?.disconnect();

		// Ensure conversation id exists
		if (conversationId) {

			// Create a WebSocket connection
			socketRef.current = socketIOClient(process.env.SOCKET_URL, {
				transports: ['websocket'],
				query: { conversationId },
			});

			// Listen for incoming commands
			socketRef.current.on(SOCKET_PROFILE_UPDATE_KEY, (message) => {

				// Get parameters from payload
				const {
					action,
					descriptors,
					riskAssessmentStarted: riskStarted,
					riskAssessmentComplete: riskComplete,
					riskAssessmentResult: riskResult,
					intent
				} = message.payload;

				// Handle action
				if (action === SOCKET_ACTIONS.PROFILE_UPDATE_DESCRIPTORS) {

					// Set descriptors
					if (descriptors && descriptors.length > 0) {
						setProfileDescriptors(descriptors);
					}

				} else if (action === SOCKET_ACTIONS.PROFILE_NEW_INTENT) {

					// Create new intents
					const newIntents = [...triageIntentsRef.current, intent];

					// Set new intent
					setTriageIntents(newIntents);
					triageIntentsRef.current = newIntents;

				} else if (action === SOCKET_ACTIONS.PROFILE_UPDATE_RISK_ASSESSMENT) {

					// Update risk assessment state
					setRiskAssessmentStarted(riskStarted);
					setRiskAssessmentComplete(riskComplete);
					setRiskAssessmentResult(riskResult);
				}
			});
		}

		// Destroys the socket reference when the connection is closed
		return () => { socketRef?.current?.disconnect(); };

	}, [conversationId]);

	// Handle actions on conversation status changed
	useEffect(() => {

		// Set profile descriptors
		setProfileDescriptors(conversation?.userProfile?.descriptors || []);

		// Set triage intents
		setTriageIntents(conversation?.userProfile?.intents || []);
		triageIntentsRef.current = conversation?.userProfile?.intents || [];

		// Set risk assessment state
		setRiskAssessmentStarted(conversation?.userProfile?.riskAssessmentStarted);
		setRiskAssessmentComplete(conversation?.userProfile?.riskAssessmentComplete);
		setRiskAssessmentResult(conversation?.userProfile?.riskAssessmentResult);

	}, [conversationStatus]);

	// Handle actions on component load
	useEffect(() => { }, []);

	// Render empty view
	const renderEmptyView = () => (
		<S.EmptyContainer>
			<S.AnimationContainer $opacity={0.7}>
				<Lottie
					loop
					play
					animationData={flatten(theme.brandAccent, pulseAnimationData)}
					style={{
						width: '70px',
						height: '70px'
					}}
				/>
			</S.AnimationContainer>
			<Typography variation="3">Waiting for activity...</Typography>
		</S.EmptyContainer>
	);

	// Render sentiment timeline
	const renderSentimentTimeline = () => {

		// Get messages with sentiment
		const sentimentMessages = (conversation?.messages || []).filter(({ sentiment }) => sentiment?.mood).map(({ sentiment, dateSent, id }) => ({ ...sentiment, dateSent, id }));

		// Return component
		if (sentimentMessages.length > 0) {
			return (
				<S.SentimentTimeline>
					<S.TimelineRow>
						<S.TimelineDivider $start />
						<S.TimelineDot />
						<Typography tag="p" variation="3" className="secondaryText" style={{ marginRight: '6px', flexShrink: '0' }}>
							{formatDateString(conversation?.startedDate, 'h:mm a')}
						</Typography>
						<Badge size="small" className="widgetBadge" variant="outlined">
							<Typography tag="p" variation="3">
								Conversation started
							</Typography>
						</Badge>
					</S.TimelineRow>
					{sentimentMessages.map((sentiment, index) => (
						<S.TimelineRow key={sentiment.id}>
							<S.TimelineDivider $end={index === sentimentMessages.length - 1} />
							<S.TimelineDot />
							<Typography tag="p" variation="3" center={false} className="secondaryText" style={{ marginRight: '6px', flexShrink: '0' }}>
								{formatDateString(sentiment.dateSent, 'h:mm a')}
							</Typography>
							<S.SentimentBadge $sentiment={sentiment.sentiment}>
								<Typography tag="p" variation="3" center={false}>
									{sentiment.mood}
								</Typography>
							</S.SentimentBadge>
						</S.TimelineRow>
					))}
				</S.SentimentTimeline>
			);
		}
		return renderEmptyView();
	};

	// Render profile descriptors
	const renderProfileDescriptors = () => {
		if (profileDescriptors && profileDescriptors.length > 0) {
			return (
				<S.Descriptors>
					{profileDescriptors.map(({ descriptor, percentage, polarity }) => (
						<S.Descriptor
							key={descriptor}
							$polarity={polarity}
						>
							<S.ProgressBar $polarity={polarity} $percentage={percentage} />
							<Badge
								size="small"
								className="widgetBadge"
								variant="outlined"
								variation="square"
							>
								<Typography tag="p" variation="3" center={false}>
									{descriptor}
									{' '}
									(
									{percentage}
									%
									)
								</Typography>
							</Badge>
						</S.Descriptor>
					))}
				</S.Descriptors>
			);
		}
		return renderEmptyView();

	};

	// Render triage flow
	const renderTriageFlow = () => {

		// Get risk assessment status
		let riskAssessmentLabel = 'Risk assessment pending';
		let riskAssessmentStatus = 'pending';
		if (riskAssessmentComplete) {
			riskAssessmentLabel = 'Risk assessment complete';
			riskAssessmentStatus = 'complete';
		} else if (riskAssessmentStarted) {
			riskAssessmentLabel = 'Risk assessment in progress';
			riskAssessmentStatus = 'in_progress';
		}

		// Render component
		return (
			<S.TriageContainer>

				{/* Conversation Started */}
				<S.TimelineRow className="largePadding">
					<S.TimelineDivider $start />
					<S.TimelineDot />
					<Typography tag="p" variation="3" className="secondaryText" style={{ marginRight: '6px' }} center={false}>
						{formatDateString(conversation?.startedDate, 'h:mm a')}
					</Typography>
					<Badge size="small" className="widgetBadge" variant="outlined">
						<Typography tag="p" variation="3" center={false}>
							Conversation started
						</Typography>
					</Badge>
				</S.TimelineRow>

				{/* Risk Assessment */}
				<S.TimelineRow className="largePadding">
					<S.TimelineDivider $end={triageIntents.length === 0} />
					<S.TimelineDot />
					{(riskAssessmentStatus === 'pending' || riskAssessmentStatus === 'in_progress') && (
						<S.TimelineAnimation $opacity={1}>
							<Lottie
								loop
								play
								animationData={flatten(theme.brandPrimaryBase, spinnerAnimationData)}
								style={{
									width: '100%',
									height: '100%'
								}}
							/>
						</S.TimelineAnimation>
					)}
					<S.SuccessContainer $isComplete={riskAssessmentStatus === 'complete'}>
						<FontAwesomeIcon icon={['fas', 'check']} />
					</S.SuccessContainer>
					<Badge size="small" className="widgetBadge" variant="outlined">
						<Typography tag="p" variation="3" center={false}>
							{riskAssessmentLabel}
						</Typography>
					</Badge>
				</S.TimelineRow>
				{riskAssessmentResult && (
					<S.TimelineRow>
						<S.TriageContent>
							<Typography tag="p" variation="3" center={false}>
								{riskAssessmentResult}
							</Typography>
						</S.TriageContent>
					</S.TimelineRow>
				)}
				{triageIntents.map(({ date, intent }, index) => (
					<>
						<S.TimelineRow key={intent} className="largePadding">
							<S.TimelineDivider $end={index === triageIntents.length - 1} />
							<S.TimelineDot />
							<Typography tag="p" variation="3" className="secondaryText" style={{ marginRight: '6px', flexShrink: '0' }} center={false}>
								{formatDateString(date, 'h:mm a')}
							</Typography>
							<Badge size="small" className="widgetBadge" variant="outlined">
								<Typography tag="p" variation="3" center={false}>
									User intent detected
								</Typography>
							</Badge>
						</S.TimelineRow>
						<S.TimelineRow key={intent} className="largePadding">
							<S.TriageContent>
								<Typography tag="p" variation="3" center={false}>
									{intent}
								</Typography>
							</S.TriageContent>
						</S.TimelineRow>
					</>
				))}
			</S.TriageContainer>
		);

	};

	// Render component
	return (
		<S.Wrapper className={className}>

			{/* Top Section */}
			<S.TopSection>

				{/* Triage Flow Container */}
				<S.FlowContainer>

					{/* Badge */}
					<Badge size="small" className="widgetBadge" variant="outlined">
						<Typography weight="medium" tag="p" variation="3">
							Triage
						</Typography>
					</Badge>

					{/* Triage Flow */}
					<S.WidgetCard className="widgetCard">{renderTriageFlow()}</S.WidgetCard>

				</S.FlowContainer>

				{/* Sentiment Timeline Container */}
				<S.SentimentContainer>

					{/* Badge */}
					<Badge size="small" className="widgetBadge" variant="outlined">
						<Typography weight="medium" tag="p" variation="3">
							Timeline
						</Typography>
					</Badge>

					{/* Sentiment Timeline */}
					<S.WidgetCard className="widgetCard">{renderSentimentTimeline()}</S.WidgetCard>

				</S.SentimentContainer>

			</S.TopSection>

			{/* Bottom Section */}
			<S.BottomSection>

				{/* Profile Descriptors Container */}
				<S.DescriptorsContainer>

					{/* Badge */}
					<Badge size="small" className="widgetBadge" variant="outlined">
						<Typography weight="medium" tag="p" variation="3">
							Survivor Profile
						</Typography>
					</Badge>

					{/* Profile Descriptors */}
					<S.WidgetCard className="flex widgetCard">{renderProfileDescriptors()}</S.WidgetCard>

				</S.DescriptorsContainer>

			</S.BottomSection>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

UserAIProfile.displayName = 'UserAIProfile';
UserAIProfile.propTypes = {
	className: PropTypes.string,
	conversationId: PropTypes.string,
	partner: PropTypes.shape()
};
UserAIProfile.defaultProps = {
	className: null,
	conversationId: null,
	partner: {}
};
