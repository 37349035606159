/*
 * File: Messenger.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 2, 2024 at 1:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Utilities
import { polarityBgColor, polarityColor } from '../../utilities/colors';

// Components
import { Padding } from '../Padding';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Color Values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const NavigationBar = styled.div`
	background-color: ${({ theme }) => theme.primaryBackground};
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	text-align: left;
	box-shadow: ${ColorValues.navigationShadow};
	position: relative;
	z-index: 99;
	padding: 28px 40px 35px;

	h2 {
		flex-grow: 1;
		text-align: left;
	}
	p {
		margin-top: 4px;
	}
	.actionLink {
		color: ${({ theme }) => theme.secondaryText};
		opacity: 0.6;
		margin-top: 10px;
		font-size: 0.6875rem;
	}
	* {
		text-align: left;
	}
	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const LogoMark = styled.img`
	width: 35px;
	height: 35px;
	margin-bottom: 12px;
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;

export const ConversationContainer = styled.div`
	flex-grow: 1;
	overflow: scroll;
	height: 100%;
	background-color: ${({ theme }) => theme.secondaryBackground};

	.fullHeight {
		height: 100%;
	}
`;

export const ChatContent = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding-top: 30px;
`;

export const MessageBar = styled.div`
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	bottom: 0px;
	align-items: center;
	width: 100%;
	gap: 12px;
	background-color: ${({ theme }) => theme.secondaryBackground};
`;

export const SenderWidget = styled.div`
	height: 60px;
	display: flex;
	background-color: ${({ theme }) => theme.plainBackground};
	box-shadow: ${ColorValues.elementDarkShadow};
	align-items: center;
	width: 100%;
	gap: 12px;
	padding: 10px 20px;

	input {
		height: 100%;
		border-radius: 30px;
		appearance: none;
		flex-grow: 1;
		border: 1px solid ${({ theme }) => theme.layoutBorder};
		padding: 0px 14px;
		font-weight: 400;
		outline: none;
		font-size: 0.975rem;

		&:focus,
		&:active {
			border: 1px solid ${({ theme }) => theme.secondaryText};
		}
	}
	button {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const SuggestionsBar = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
	padding: 6px 20px 5px;
	width: 100%;
`;

export const Prompt = styled.div`
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 18px 18px 4px 18px;
	display: inline-flex;
	padding: 2px 12px;
	cursor: pointer;

	p {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const RightMessageContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
`;

export const LeftMessageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	gap: 4px;
`;

export const MessageContentContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 6px;
`;

export const RightMessage = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	border-radius: 18px 18px 4px 18px;
	padding: 6px 16px;
	display: inline-flex;
	max-width: 80%;

	* {
		text-align: left;
		color: ${ColorValues.white};
		white-space: pre-wrap;
		width: 100%;
		overflow-x: hidden;
	}
`;

export const LeftMessage = styled.div`
	background-color: ${ColorValues.white};
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 18px 18px 18px 4px;
	padding: 6px 16px;
	display: inline-flex;
	max-width: 80%;

	* {
		text-align: left;
		white-space: pre-wrap;
		width: 100%;
		overflow-x: hidden;
	}
`;

export const ChatIcon = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		font-size: 0.8rem;
		color: ${ColorValues.white};
	}
`;

export const ConversationFooter = styled.div`
	width: 100%;
	height: 80px;
	flex-shrink: 0;
`;

export const TypingContainer = styled.div`
	align-items: center;
  	display: flex;
  	height: 22px;
`;

export const Dot = styled.div`
	animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
	background-color: #aaaaaa;
	border-radius: 50%;
	height: 7px;
	margin-right: 4px;
	vertical-align: middle;
	width: 7px;
	display: inline-block;

	&:nth-child(1) {
		animation-delay: 200ms;
	}
	&:nth-child(2) {
		animation-delay: 300ms;
	}
	&:nth-child(3) {
		animation-delay: 400ms;
	}
	&:last-child {
		margin-right: 0;
	}

	@keyframes mercuryTypingAnimation {
		0% {
			transform: translateY(0px);
			background-color: #aaaaaa;
		}
		28% {
			transform: translateY(-7px);
			background-color: #c9c9c9;
		}
		44% {
			transform: translateY(0px);
			background-color: #dadada;
		}
	}
`;

export const SentimentContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;

	p {
		font-size: 0.7rem;
	}
	& > p {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const SentimentBadge = styled.div`
	margin-left: 36px;
	border: 1px solid ${({ $sentiment, theme }) => polarityColor($sentiment, theme)};
	border-radius: 18px;
	padding: 2px 12px;
	color: ${({ $sentiment, theme }) => polarityColor($sentiment, theme)};
	background-color: ${({ $sentiment, theme }) => polarityBgColor($sentiment, theme)};

	p {
		font-size: 0.7rem;
	}
`;
