/*
 * File: AppNavigation.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 10, 2024 at 3:01 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../Padding';

// Constants
import { AppNavTopbarHeight } from '../../styles/constants';


/**
 * Styles
 */

export const Navigation = styled.div`
	display: flex;
	height: auto !important;
	min-height: 100%;
	flex-direction: column;
`;

export const Wrapper = styled.div`
	display: flex;
	overflow: hidden;
	height: 100%;
	flex-grow: 1;
`;

export const Content = styled.div`
	flex: 1;
  	display: flex;
	overflow: auto;
	overflow-x: hidden;
	flex-direction: column;
	padding-top: ${({ $isFixed }) => ($isFixed ? AppNavTopbarHeight : '0')}px;
`;

export const ContentPadding = styled(Padding)`
	display: flex;
	flex-grow: 1;
`;
