/*
 * File: fontawesome.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 30, 2024 at 11:50 PM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 5, 2024 at 12:33 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { library } from '@fortawesome/fontawesome-svg-core';

// Icons
import {
	faComment,
	faUser,
	faPaperPlaneTop,
	faCheck
} from '@fortawesome/pro-solid-svg-icons';
import {
	faXmark,
} from '@fortawesome/pro-light-svg-icons';


/**
 * Init
 */

export function loadIcons() {

	// Add icons to library
	library.add(
		faComment,
		faUser,
		faPaperPlaneTop,
		faXmark,
		faCheck
	);
}
