/*
 * File: Messenger.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 2:16 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 } from 'uuid';
import socketIOClient from 'socket.io-client';

// Utilities
import { toastError } from '../../utilities/toaster';
import { getAssetUrl } from '../../../utilities/partner';

// Services
import { postConversationMessage } from '../../services/conversation';

// Slices
import { pushMessageToConversation, updateMessage } from '../../store/slices/conversation/conversation.slice';

// Components
import { Spinner } from '../Spinner';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';
import { ErrorComponent } from '../ErrorComponent';

// Constants
import {
	CONVERSATION_ROLES, SOCKET_ACTIONS, SOCKET_MESSAGE_KEY, UI_MODE_OPTIONS
} from '../../../Constants';

// Styles
import * as S from './Messenger.styles';
import { formatDateString } from '../../../utilities/dateTime';


/**
 * Component
 */

export const Messenger = ({
	className, conversationId, role, isVisible
}) => {

	// Create state handlers
	const [inputValues, setInputValues] = useState({});
	const [showTypingBubble, setShowTypingBubble] = useState(false);
	const [suggestedPrompts, setSuggestedPrompts] = useState([]);

	// Get hooks
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current conversation from hook
	const conversation = useSelector((state) => state.conversation.value);
	const conversationStatus = useSelector((state) => state.conversation.status);

	// Get current partner from hook
	const partner = useSelector((state) => state.partner.value);

	// Create reference for components
	const isMounted = useRef(true);
	const messagesEndRef = useRef(null);
	const socketRef = useRef();
	const inputValuesRef = useRef({});

	// Handle send message
	const sendMessage = async () => {

		// Get parameters
		const { content } = inputValuesRef.current;

		// Validate parameters
		if (!content || validator.isEmpty(content, { ignore_whitespace: true })) {
			return;
		}

		// Append message
		dispatch(pushMessageToConversation({
			id: v4(),
			isUserSent: true,
			dateSent: new Date(),
			text: content
		}));

		// Clear text
		setInputValues({});
		inputValuesRef.current = {};

		// Show thinking bubble
		setTimeout(() => {
			setShowTypingBubble(true);
		}, 1000);

		// Send chat message
		try {

			// Send message
			const { data: { response } } = await postConversationMessage({ text: content, conversationId });

			// Hide thinking bubble
			setShowTypingBubble(false);

			// Append response message
			dispatch(pushMessageToConversation(response));

		} catch (e) {

			// Hide thinking bubble
			setShowTypingBubble(false);

			// Show error
			toastError(uiMode, partner, 'Whoops. We\'re having trouble sending your message. Please try again.');
		}
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
		inputValuesRef.current = { ...inputValues, [name]: value };
	};

	// Handle keyboard shortcut function
	const shortcutFunction = useCallback((event) => {
		if (event.keyCode === 13) {
			sendMessage();
		}
	}, []);

	// Handle actions on conversation message page
	useEffect(() => {

		// Scroll messages into view
		messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });

	}, [conversation.messages]);

	// Handle actions on visibility changed
	useEffect(() => {
		if (isVisible) {

			// Scroll messages into view
			messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });

		}
	}, [isVisible]);

	// Handle actions on session id change
	useEffect(() => {

		// Disconnect and refresh socket
		if (socketRef.current) socketRef?.current?.disconnect();

		// Ensure conversation id exists
		if (conversationId) {

			// Create a WebSocket connection
			socketRef.current = socketIOClient(process.env.SOCKET_URL, {
				transports: ['websocket'],
				query: { conversationId },
			});

			// Listen for incoming commands
			socketRef.current.on(SOCKET_MESSAGE_KEY, (message) => {

				// Get parameters from payload
				const {
					action, role: intendedRole, message: messageObj, suggestedPrompts: newSuggestedPrompts
				} = message.payload;

				// Check role
				if (intendedRole === role) {

					// Handle action
					if (action === SOCKET_ACTIONS.NEW_MESSAGE) {

						// Append response message
						dispatch(pushMessageToConversation(messageObj));

					} else if (action === SOCKET_ACTIONS.UPDATE_MESSAGE) {

						// Update message
						dispatch(updateMessage(messageObj));

					} else if (action === SOCKET_ACTIONS.UPDATE_SUGGESTED_PROMPTS) {

						// Update suggested prompts
						setSuggestedPrompts(newSuggestedPrompts);
					}
				}
			});
		}

		// Destroys the socket reference when the connection is closed
		return () => { socketRef?.current?.disconnect(); };

	}, [conversationId]);

	// Handle actions on conversation status changed
	useEffect(() => {

		// Set suggested prompts
		setSuggestedPrompts(conversation.suggestedPrompts);

	}, [conversationStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Add event listener
		document.addEventListener('keydown', shortcutFunction, false);

		// Handle actions on dismount
		return () => {

			// Remove event listener
			document.removeEventListener('keydown', shortcutFunction, false);

			// Dismount
			isMounted.current = false;
		};

	}, []);

	// Handle render component
	const renderComponent = () => {
		if (conversationStatus === 'idle' || conversationStatus === 'loading') {
			return <Spinner partner={partner} />;
		}
		if (conversationStatus === 'error') {
			return <ErrorComponent />;
		}
		return (
			<>
				{/* Messages */}
				{conversation.messages.map((message) => ((message.isUserSent && role === CONVERSATION_ROLES.USER) || (!message.isUserSent && role === CONVERSATION_ROLES.SYSTEM)
					? (
						<S.RightMessageContainer ref={messagesEndRef} key={message.id}>
							<S.RightMessage>
								<Typography tag="p" variation="2" weight="light">{message.text}</Typography>
							</S.RightMessage>
						</S.RightMessageContainer>
					)
					: (
						<S.LeftMessageContainer ref={messagesEndRef} key={message.id}>
							<S.MessageContentContainer>
								<S.ChatIcon>
									<FontAwesomeIcon icon={role === CONVERSATION_ROLES.USER ? ['fas', 'comment'] : ['fas', 'user']} />
								</S.ChatIcon>
								<S.LeftMessage>
									<Typography tag="p" variation="2" weight="light">{message.text}</Typography>
								</S.LeftMessage>
							</S.MessageContentContainer>
							{message?.sentiment?.mood && (
								<S.SentimentContainer>
									<S.SentimentBadge $sentiment={message.sentiment.sentiment}>
										<Typography tag="p" variation="3">
											{message.sentiment.mood}
										</Typography>
									</S.SentimentBadge>
									<Typography tag="p" variation="3">
										{formatDateString(message.dateSent, 'h:mm a')}
									</Typography>
								</S.SentimentContainer>
							)}
						</S.LeftMessageContainer>
					)))}

				{/* Typing Bubble */}
				{showTypingBubble && role === CONVERSATION_ROLES.USER && (
					<S.LeftMessageContainer ref={messagesEndRef}>
						<S.MessageContentContainer>
							<S.ChatIcon>
								<FontAwesomeIcon icon={['fas', 'comment']} />
							</S.ChatIcon>
							<S.LeftMessage>
								<S.TypingContainer>
									<S.Dot />
									<S.Dot />
									<S.Dot />
								</S.TypingContainer>
							</S.LeftMessage>
						</S.MessageContentContainer>
					</S.LeftMessageContainer>
				)}
			</>
		);
	};

	// Render component
	return (
		<S.Wrapper className={className}>

			{/* Navigation Bar */}
			<S.NavigationBar>
				{role === CONVERSATION_ROLES.USER ? (
					<>
						<S.LogoMark src={getAssetUrl(uiMode.mode === UI_MODE_OPTIONS.DARK ? partner?.logo_mark_light : partner?.logo_mark_dark, '/public/assets/logos/')} />
						<Typography tag="h2" weight="bold">Hi there, welcome to lighthouse 👋</Typography>
						<Typography tag="p" weight="regular">I&apos;m here to help</Typography>
						{/* <Typography tag="p" weight="light" variation="3" className="actionLink">
							Looking for something fast?
							{' '}
							<LocaleLink to="https://stories.ourwave.org/answers" target="_blank">Find answers</LocaleLink>
							{' '}
							or
							{' '}
							<LocaleLink to="https://stories.ourwave.org/resources" target="_blank">explore resources</LocaleLink>
						</Typography> */}
					</>
				) : (
					<Typography tag="h2" weight="bold">Messages</Typography>
				)}
			</S.NavigationBar>

			{/* Chat Content */}
			<S.ConversationContainer className="scroller" id="scroller-chat">
				<S.ContentPadding left right className="fullHeight">
					<S.ChatContent>
						{renderComponent()}
						<S.ConversationFooter />
					</S.ChatContent>
				</S.ContentPadding>
			</S.ConversationContainer>

			{/* Message Bar */}
			{role === CONVERSATION_ROLES.USER && (
				<S.MessageBar>

					{/* Suggestions Bar */}
					{suggestedPrompts && suggestedPrompts.length > 0 && (
						<S.SuggestionsBar>
							{suggestedPrompts.map((prompt) => (
								<S.Prompt key={prompt} onClick={() => { handleOnChange({ target: { name: 'content', value: prompt } }); }}>
									<Typography tag="p" variation="2" weight="light">{prompt}</Typography>
								</S.Prompt>
							))}
						</S.SuggestionsBar>
					)}

					{/* Sender Widget */}
					<S.SenderWidget>
						<input
							placeholder="How can I help?"
							className="animate"
							name="content"
							type="text"
							value={inputValues.content || ''}
							onChange={handleOnChange}
						/>
						<IconButton
							icon={['fas', 'paper-plane-top']}
							size={1.4}
							onClick={sendMessage}
						/>
					</S.SenderWidget>
				</S.MessageBar>
			)}

		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Messenger.displayName = 'Messenger';
Messenger.propTypes = {
	className: PropTypes.string,
	conversationId: PropTypes.string,
	role: PropTypes.oneOf(Object.values(CONVERSATION_ROLES)),
	isVisible: PropTypes.bool
};
Messenger.defaultProps = {
	className: null,
	conversationId: null,
	role: CONVERSATION_ROLES.USER,
	isVisible: false
};
