/*
 * File: index.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on July 31, 2024 at 12:16 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 9:58 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getConversation = ({ conversationId }) => api.get(`/conversation/${conversationId}`);

export const postBeginConversation = ({ conversationId }) => api.post('/conversation/start', { conversationId });

export const postConversationMessage = ({ text, conversationId }) => api.post('/conversation/message', { text, conversationId });
