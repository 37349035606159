/*
 * File: Button.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 31, 2024 at 11:16 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */


// Modules
import React, {
	forwardRef, useCallback, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import { flatten } from 'lottie-colorify';

// Components
import { Typography } from '../Typography';

// Animation
import animationData from '../../assets/animations/spinner.json';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './Button.styles';
import { ColorValuesCreator, DarkTheme, LightTheme } from '../../styles/colors';


/**
 * Component
 */

export const Button = forwardRef(({
	children, size, variant, variation, type, className, isLoading, onClick, disabled, keyboardShortcut, ...rest
}, ref) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current partner from hook
	const partner = useSelector((state) => state.partner.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme(partner) : LightTheme(partner);

	// Get color values
	const ColorValues = ColorValuesCreator(partner);

	// Handle keyboard shortcut
	const displayKeyboardShortcut = useMemo(() => (keyboardShortcut != null ? String.fromCharCode(keyboardShortcut).toUpperCase() : null), [keyboardShortcut]);

	// Handle keyboard shortcut function
	const shortcutFunction = useCallback((event) => {
		if (event.keyCode === keyboardShortcut && !disabled) {
			if (onClick) onClick();
		}
	}, [keyboardShortcut, disabled, onClick]);

	useEffect(() => {
		if (keyboardShortcut != null) {

			// Add event listener
			document.addEventListener('keydown', shortcutFunction, false);

			// Remove event listener
			return () => {
				document.removeEventListener('keydown', shortcutFunction, false);
			};
		}
		return () => {};
	}, [shortcutFunction]);

	// Render animation data
	const renderAnimationData = () => {
		switch (variation) {
			case 'default': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.primaryButton, animationData);
				}
				return flatten(currentTheme.primaryButton, animationData);
			}
			case 'white': {
				if (variant === 'solid') {
					return flatten(ColorValues.dark, animationData);
				} if (variant === 'outline') {
					return flatten(ColorValues.white, animationData);
				}
				return flatten(ColorValues.white, animationData);
			}
			case 'dark': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.darkButton, animationData);
				}
				return flatten(currentTheme.darkButton, animationData);
			}
			case 'secondary': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.secondaryButton, animationData);
				}
				return flatten(currentTheme.secondaryButton, animationData);
			}
			case 'warning': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.statusDangerBase, animationData);
				}
				return flatten(currentTheme.statusDangerBase, animationData);
			}
			default:
				break;
		}
		return flatten(currentTheme.primaryButton, animationData);
	};

	// Render component
	return (
		<S.Button ref={ref} size={size} type={type} variant={variant} variation={variation} className={className ? `${className} animate` : 'animate'} onClick={onClick} disabled={disabled} {...rest}>
			{isLoading && (
				<S.SpinnerContainer>
					<Lottie loop play animationData={renderAnimationData()} style={{ height: '100%' }} />
				</S.SpinnerContainer>
			)}
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, { style: { visibility: isLoading ? 'hidden' : 'visible' } });
				}
				return <S.Span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{child}</S.Span>;
			})}
			{displayKeyboardShortcut && (
				<S.ShortcutContainer>
					<Typography>{displayKeyboardShortcut}</Typography>
					<S.ShortcutBackground className="shortcut" />
				</S.ShortcutContainer>
			)}
		</S.Button>
	);
});


/**
 * Configuration
 */

Button.displayName = 'Button';
Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	size: PropTypes.oneOf(['large', 'medium', 'small']),
	variant: PropTypes.oneOf(['solid', 'outline', 'text']),
	variation: PropTypes.oneOf(['default', 'white', 'dark', 'secondary', 'warning']),
	disabled: PropTypes.bool,
	className: PropTypes.string,
	type: PropTypes.string,
	isLoading: PropTypes.bool,
	onClick: PropTypes.func,
	keyboardShortcut: PropTypes.number
};
Button.defaultProps = {
	children: null,
	size: 'medium',
	variant: 'solid',
	variation: 'default',
	disabled: false,
	className: null,
	type: 'button',
	isLoading: false,
	onClick: null,
	keyboardShortcut: null
};
