/*
 * File: Logo.styles.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 10, 2024 at 2:26 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mediumBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	.smallScreen {
		display: block;
	}
	.largeScreen {
		display: none;
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		.smallScreen {
			display: none;
		}
		.largeScreen {
			display: block;
		}
	}
`;

export const LogoImage = styled.img`
	height: 80px;
	width: auto;
`;
