/*
 * File: ExplanationStep.jsx
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 2:25 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, forwardRef
} from 'react';
import PropTypes from 'prop-types';

// Components
import {
	CircleDecoration,
	Emoji,
	Typography,
} from '../../../../../components';

// Styles
import * as S from './ExplanationStep.styles';


/**
 * Component
 */

export const ExplanationStep = forwardRef(({
	className, updateStep
}, ref) => {

	// Handle continue action
	const handleContinue = () => {

		// Move to messenger
		updateStep(2);
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Padding */}
			<S.StepPadding left right>

				{/* Content */}
				<Typography tag="h3" weight="bold">
					<span style={{ marginRight: '8px' }}>Welcome</span>
					<Emoji symbol="👋" size={1.5} label="Wave" />
				</Typography>
				<Typography tag="h1" weight="bold">
					lighthouse provides timely resources for survivors to help them find healing after trauma.
				</Typography>
				<Typography tag="p" weight="regular">
					This chat experience can be embedded on a website, mobile + other integrations.
				</Typography>

				{/* Continue Action */}
				<S.ActionButton onClick={handleContinue} size="large">Get started</S.ActionButton>

			</S.StepPadding>

			{/* Decoration */}
			<CircleDecoration />

		</S.StepContainer>
	);
});


/**
 * Configuration
 */

ExplanationStep.displayName = 'ExplanationStep';
ExplanationStep.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func
};
ExplanationStep.defaultProps = {
	className: null,
	updateStep: null
};
