/*
 * File: App.js
 * Project: our-wave-ai
 *
 * Created by Brendan Michaelsen on June 13, 2024 at 10:17 AM
 * Copyright © 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 2:19 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useRef
} from 'react';
import {
	Routes, Route, useLocation, useSearchParams
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { toastError, toastMessage, toastSuccess } from '../../utilities/toaster';
import { scrollPageTo } from '../../utilities/position';

// Styles
import { GlobalStyles } from '../../styles/global';
import { DarkTheme, LightTheme } from '../../styles/colors';

// Slices
import { updateLocale, clearLocale } from '../../store/slices/locale/locale.slice';
import { updatePartner, clearPartner } from '../../store/slices/partner/partner.slice';

// Routes
import { Routes as RoutesCreator } from './routes/Routes';

// Constants
import { UI_MODE_OPTIONS, ALERT_STYLES } from '../../../Constants';

// Configuration
import CORE from '../../../partners/partner.core';


/**
 * Component
 */

const App = (data) => {

	// Set component props from server
	let props = data || {};
	const { isHot } = props;

	// Get default partner if hot mode
	if (isHot === true) {
		props = {
			...props,
			partner: CORE
		};
	}

	// Use hooks
	const location = useLocation();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams({});

	// Initialize render count
	const renderCount = useRef(-1);

	// Get current UI mode from hook
	let uiMode = useSelector((state) => state.ui.value);
	if (props?.state?.ui) uiMode = props?.state?.ui;

	// Get component state on client
	if (typeof window !== 'undefined') {
		try { props = JSON.parse(decodeURIComponent(window.document.getElementById('data-state').innerHTML)); } catch (e) { }
	}

	// Get current partner from hook
	const clientPartner = useSelector((state) => state.partner.value);
	const statePartner = props?.partner || clientPartner;

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme(statePartner) : LightTheme(statePartner);

	// Handle actions on component load
	useEffect(() => {

		// Set current partner in state
		if (props?.partner && props?.partner?.id) dispatch(updatePartner(props?.partner));
		else dispatch(clearPartner());

		// Set locale in state
		if (props.locale && props.locale.localeId) dispatch(updateLocale(props.locale));
		else dispatch(clearLocale());

		// Check for alert to display
		if (props?.alert) {

			// Get alert parameters
			const { message, style } = props.alert;

			// Show toast
			setTimeout(() => {
				if (style === ALERT_STYLES.INFO) {
					toastMessage(uiMode, statePartner, message);
				} else if (style === ALERT_STYLES.ERROR) {
					toastError(uiMode, statePartner, message);
				}
			}, 400);
		}
	}, []);

	// Update render count
	useEffect(() => {
		renderCount.current += 1;
	});

	// Handle actions on route change
	useEffect(() => {

		// Get query parameters
		const queryObj = Object.fromEntries([...searchParams]);

		// Check for error query
		if (searchParams.has('error')) {

			// Get error message
			const errorMessage = decodeURIComponent(searchParams.get('error'));

			// Clear error query parameters
			const { error, code, ...additionalParams } = queryObj;
			setSearchParams(additionalParams);

			// Display error toast
			setTimeout(() => {
				toastError(uiMode, statePartner, errorMessage);
			}, 400);
		}

		// Check for success query
		if (searchParams.has('success')) {

			// Get success message
			const successMessage = decodeURIComponent(searchParams.get('success'));

			// Clear success query parameters
			const { success, ...additionalParams } = queryObj;
			setSearchParams(additionalParams);

			// Display success toast
			setTimeout(() => {
				toastSuccess(uiMode, statePartner, successMessage);
			}, 400);
		}

		// Handle hash scroll
		const { hash } = location;
		if (!hash) {
			scrollPageTo(0, 0, { behavior: 'smooth' });
		} else {
			const id = hash.replace('#', '');
			const element = document.getElementById(id);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			} else {
				scrollPageTo(0, 0, { behavior: 'smooth' });
			}
		}
	}, [location]);

	// Create routes with props
	const RoutesManifest = RoutesCreator(props, isHot);

	// Set up router render function
	const renderRouter = () => (
		<Routes>
			{RoutesManifest.map((route, index) => {
				const { component: Component } = route;
				return <Route key={route.path || `route-${index}`} path={route.path} element={Component} />;
			})}
		</Routes>
	);

	// Render router
	return (
		<ThemeProvider theme={currentTheme}>
			<GlobalStyles />
			{renderRouter()}
		</ThemeProvider>
	);
};


/**
 * Configuration
 */

App.propTypes = {
	locale: PropTypes.shape(),
	state: PropTypes.shape(),
	alert: PropTypes.shape(),
	partner: PropTypes.shape(),
	isHot: PropTypes.bool
};
App.defaultProps = {
	locale: {},
	state: {},
	alert: {},
	partner: {},
	isHot: false
};


/**
 * Exports
 */

export default App;
